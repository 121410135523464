import { api } from '../../../../api/datasource';

const APIURLEntidade = "/cadastro/entidade"
const APIURLTipo = "/financeiro/documento_tipo"

export const GetEntidadeData = async () => {
    const res = await api.get(APIURLEntidade)    
    return res.data
}

export const GetTipoData = async () => {
    const res = await api.get(APIURLTipo)
    return res.data
}