import { api } from '../../../../api/datasource'
import CustomStore from 'devextreme/data/custom_store'

const baseURL = "/financeiro/documento_grupo_produto_servico/"

export const dataSource = (grupoId) => {
    const apiURL = baseURL + grupoId

    

    return new CustomStore({
        key: 'id',

        load: async (options) => {
            const res = await api.get(apiURL)
            // console.log(res)
            return res
        },
        insert: async (values) => {
            // console.log(values)

            values.fin_documento_grupo_id = grupoId
            const res = await api.post(apiURL, values)
            // res.data.instance
            return await res.data.instance
        },

        update: async (obj, values) => {
            const res = await api.put(apiURL, obj, values)
            return res.data
        },

        remove: async (key) => {
            await api.delete(apiURL + '/' + key)
        },

        // byKey: async (key) => {
        //     // console.error('ATENÇÃO FUNÇÃO BY KEY NÃO IMPLEMENTADA')
        //     // console.log(key)
        // }
    })

}