import React from 'react'
import TextBoxDevExtreme from 'devextreme-react/text-box';

export const TextBox = props => {
    return <div id={props.id} className={props.className}>
        <div className="d-flex">
            <div className="p-0 flex-grow-1">
                <TextBoxDevExtreme
                    value={props.value}
                    label={props.label}
                    maskRules={props.maskRules}
                    mask={props.mask || ""}
                    labelMode={"static"}
                    stylingMode={props.stylingMode ?? "underlined"}
                    validationStatus={!props.value && props.required == true ? "invalid" : "valid"}
                    onValueChanged={(e) => {
                        if (e.event) {                            
                            props.onChange(e.value)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}