import '../financeiro-documento.css'
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { DefaultContainer } from '../../../../components/template/Main'
import { GetTipoData, GetEntidadeData } from './util';
import { LoadAll } from '../utils/Documento'
import DataGrid, { Sorting, Scrolling, LoadPanel, Toolbar, Column, Lookup, Format, GroupPanel, Item, Paging, SearchPanel, } from 'devextreme-react/data-grid';

import './DocumentoList.css';

export const DocumentoList = props => {
    document.title = 'MyMoney - Documento (lista)'
    const [entidadeData, setEntidadeData] = useState([])
    const [tipoData, setTipoData] = useState([])
    const navigate = useNavigate()
    const [documentos, setDocumentos] = useState([])


    const LoadData = async () => {
        setTipoData(await GetTipoData())
        setEntidadeData(await GetEntidadeData())

        const documentosData = await LoadAll()
        setDocumentos(documentosData)
    }

    useEffect(() => {
        const asyncEffect = async () => {
            await LoadData()
        }
        asyncEffect()
    }, [])

    const cellRenderId = (cell) => <a href={'/financeiro/documentos/' + cell.data.uuid} target="_blank">{cell.data.id}</a>

    // return <DefaultContainer title={"Lista de Documentos"}>

    //     {/* <div id={"documento-list-container"}> */}

    //         <div className="bg-danger h-100">
    //             GRID
    //         </div>
    //     {/* </div> */}

    // </DefaultContainer>


    return <DefaultContainer title={"Lista de Documentos"}>

        <DataGrid
            dataSource={documentos}
            allowColumnResizing={true}
            allowColumnReordering={true}            
            rowAlternationEnabled={true}
            showColumnLines={true}
            showBorders={true}
            height={"100%"}
            // height={"80vh"}
            onRowDblClick={(e) => navigate('/financeiro/documentos/' + e.data.uuid)}
        >

            <Toolbar>
                <Item location="before">
                    <div className="d-flex align-items-stretch p-2">

                        <div className="p-0 m-0 align-self-end fw-bold">
                            <span className='me-2'>{'Financeiro > Documentos'}</span>
                            <i type="button" className="mx-2 fas fa-sync-alt" onClick={async () => await LoadData()} />
                            <i type="button" className="mx-2 fa-solid fa-plus" onClick={async () => navigate('/financeiro/documentos/create')} />
                        </div>
                    </div>
                </Item>
                <Item name="searchPanel" />

            </Toolbar>

            <Paging defaultPageSize={20} />
            {/* <Sorting mode="none" /> */}
            <Scrolling mode="infinite" />
            <LoadPanel enabled={true} />

            <GroupPanel visible={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />

            <Column caption="Id" dataField="id" alignment="center" dataType="number" width={70} allowEditing={false}
                sortOrder={'desc'}
                cellRender={cellRenderId}

            />


            <Column caption="Emissão" dataField="emissao" alignment="center" dataType="date" width={100}
            // sortOrder={'desc'}
            />

            <Column dataField="cad_entidade_id" caption="Entidade" width={250}>
                <Lookup dataSource={entidadeData} valueExpr="id" displayExpr="nome" />
            </Column>

            <Column dataField="cad_fin_documento_tipo_id" caption="Tipo" width={200}>
                <Lookup dataSource={tipoData} valueExpr="id" displayExpr="nome" />
            </Column>

            <Column caption="Itens: total" dataField="itens_valor_total" alignment="center" dataType="number" width={100}>
                <Format type="fixedPoint" precision={2} />
            </Column>

            <Column caption="Fin: total" dataField="financeiro_valor_total" alignment="center" dataType="number" width={100}>
                <Format type="fixedPoint" precision={2} />
            </Column>

            <Column caption="Fin: aberto" dataField="financeiro_valor_em_aberto" alignment="center" dataType="number" width={100}>
                <Format type="fixedPoint" precision={2} />
            </Column>

            <Column caption="Prox. Vcto." dataField="data_proximo_vencimento" alignment="center" dataType="date" width={100} />

            <Column caption="Observação" dataField="observacao" dataType="string" minWidth="120" />
        </DataGrid>
    </DefaultContainer >


}