import React, { useRef, useState, useEffect } from 'react'
import { Popup } from '../../../../../components/Popup/Popup'
import { api } from '../../../../../api/datasource'
import { CheckBox } from 'devextreme-react/check-box'
import SelectBox from 'devextreme-react/select-box'

import { Save, getParamsData } from './utilsAPI'
import { DefaultContainer } from '../../../../../components/template/Main'
import { LoadCadastro } from '../../utils/LoadCadastro'
import { DateBox } from '../../../../../components/Editors/DateBox'

const Row = props => {
    return <div className="row p-2 align-items-center">
        <div className="col-sm-3">{props.title}</div>
        <div className="col-sm-5">{props.component}</div>
    </div>
}

export const Params = props => {
    const [data, setData] = useState()
    const [dataFinanceiroConta, setDataFinanceiroConta] = useState()
    const [dataFinanceiroMovimentacaoTipo, setDataFinanceiroMovimentacaoTipo] = useState()
    const [compensado, setCompensado] = useState()
    const [posicaoNovaLinha, setPosicaoNovaLinha] = useState()
    const [conta, setConta] = useState()
    const [tipo, setTipo] = useState()

    const [dataSistema, setDataSistema] = useState()
    const [periodoLiberadoInicial, setPeriodoLiberadoInicial] = useState()
    const [periodoLiberadoFinal, setPeriodoLiberadoFinal] = useState()


    useEffect(() => {
        const load = async () => {
            try {
                const paramsData = await getParamsData()
                setData(paramsData)
                console.log('paramsData:', paramsData)

                const resDataFinanceiroConta = await LoadCadastro('/cadastro/financeiro_conta')
                const resDataFinanceiroMovimentacaoTipo = await LoadCadastro('/cadastro/financeiro_movimentacao_tipo')

                setDataFinanceiroConta(resDataFinanceiroConta)
                setDataFinanceiroMovimentacaoTipo(resDataFinanceiroMovimentacaoTipo)

                if (
                    paramsData &&
                    paramsData.default_conta_id &&
                    paramsData.default_tipo_id &&
                    resDataFinanceiroConta &&
                    resDataFinanceiroConta.length > 0
                ) {
                    setCompensado(paramsData.default_compensado)
                    setPosicaoNovaLinha(paramsData.default_new_row_position)
                    setConta(parseInt(paramsData.default_conta_id, 10))
                    setTipo(parseInt(paramsData.default_tipo_id, 10))
                    setDataSistema(paramsData.data_sistema)

                    setPeriodoLiberadoInicial(paramsData.periodo_liberado_inicial)
                    setPeriodoLiberadoFinal(paramsData.periodo_liberado_final)

                } else {
                    console.log('Erro ao setar dados.')
                }
            } catch (error) {
                console.error('Ocorreu um erro:', error)
            }
        }

        load();
    }, []);


    if (!dataFinanceiroConta) return <>AGUARDE</>

    return <DefaultContainer>
        <div>
            <div className='p-0 m-0 h4 fw-bold'>Parâmetros</div>
            <hr className='p-0 m-0'></hr>

            {/* Movimentações */}
            <div className="row p-2 align-items-center"><div className='h6 fw-bold'><ins>Padrão para novas Movimentações</ins></div></div>

            <Row title={"Compensado"} component={<CheckBox defaultValue={compensado} onValueChanged={(e) => Save("default_compensado", e.value, setCompensado)} />} />
            <Row title={"Posição de nova Linha"} component={<SelectBox
                value={posicaoNovaLinha}
                onValueChanged={(e) => Save("default_new_row_position", e.value, setPosicaoNovaLinha)}
                valueExpr={"id"}
                displayExpr={"nome"}
                items={[{ id: "first", nome: "Primeira" }, { id: "last", nome: "Última" },]}
            />} />

            <Row title={"Conta"} component={<SelectBox
                value={conta}
                onValueChanged={(e) => Save("default_conta_id", e.value, setConta)}
                valueExpr={"id"}
                displayExpr={"nome"}
                searchEnabled={true}
                items={dataFinanceiroConta}
                showClearButton={true}
            />} />

            <Row title={"Tipo"} component={<SelectBox
                value={tipo}
                onValueChanged={(e) => Save("default_tipo_id", e.value, setTipo)}
                valueExpr={"id"}
                displayExpr={"nome"}
                items={dataFinanceiroMovimentacaoTipo}
                showClearButton={true}
            />} />

{/* 
            <Row title={"Data Sistema"} component={
                <DateBox id="data_sistema"
                    onChange={(e) => Save("data_sistema", e, setDataSistema)}
                    value={dataSistema}
                />
            } /> */}

            <Row title={"Período liberado inicial"} component={
                <DateBox id="periodo_liberado_inicial"
                    onChange={(e) => Save("periodo_liberado_inicial", e, setPeriodoLiberadoInicial)}
                    value={periodoLiberadoInicial}
                />
            } />

            <Row title={"Período liberado final"} component={
                <DateBox id="periodo_liberado_final"
                    onChange={(e) => Save("periodo_liberado_final", e, setPeriodoLiberadoFinal)}
                    value={periodoLiberadoFinal}
                />
            } />



        </div>
    </DefaultContainer>
}