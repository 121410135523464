import { showMessage } from '../../../components/template/Messages/Messages';
import React, { useEffect, useState } from 'react'
import { api } from '../../../api/datasource';
import './financeiro-documento.css'
import { DefaultContainer } from '../../../components/template/Main'
import Entidade from '../../cadastro/Entidade';
import { SelectBox as SibeSelectBox } from '../../../components/Editors/SelectBox'
import { TextBox as SibeTextBox } from '../../../components/Editors/TextBox'
import SelectBox from 'devextreme-react/select-box'
import { DateBox } from '../../../components/Editors/DateBox'
import { TextBox } from '../../../components/Editors/TextBox'
import { NumberBox } from '../../../components/Editors/NumberBox'
import { LoadCadastro } from './utils/LoadCadastro'
import { getParamsData } from './Movimentacoes/Params/utilsAPI'
import { setDataVencimento } from './Movimentacoes/utilsUI';

import { GetObjectById } from '../../../utils/GetObjectById';

const Row = props => {
    return <div className="row p-2 align-items-center">
        <div className="col-sm-3">{props.title}</div>
        <div className="col-sm-5">{props.component}</div>
    </div>
}

export const CreateMovimento = props => {




    const [contaValue, setContaValue] = useState()


    const [tipoValue, setTipoValue] = useState()
    const [entidadeValue, setEntidadeValue] = useState()
    const [entidadeId, setEntidadeId] = useState()
    const [emissaoValue, setEmissaoValue] = useState()
    const [valorValue, setValorValue] = useState()
    const [obsValue, setObsValue] = useState()

    const [dataConta, setDataConta] = useState()
    const [dataTipo, setDataTipo] = useState()
    const [dataEntidade, setDataEntidade] = useState([])


    useEffect(() => {
        const asyncEffect = async () => {
            const paramsData = await getParamsData()


            const resConta = await LoadCadastro('/cadastro/financeiro_conta')
            const resTipo = await LoadCadastro('/cadastro/financeiro_movimentacao_tipo')
            const resEntidade = await LoadCadastro('/cadastro/entidade')
            setDataConta(resConta)
            setDataTipo(resTipo)
            setDataEntidade(resEntidade)

            if (
                paramsData &&
                paramsData.default_conta_id &&
                paramsData.default_tipo_id &&
                resConta &&
                resTipo.length > 0
            ) {
                setContaValue(parseInt(paramsData.default_conta_id, 10))
                setTipoValue(parseInt(paramsData.default_tipo_id, 10))
            } else {
                console.log('Erro ao setar dados.')
            }

        }
        asyncEffect()
    }, [])

    return <DefaultContainer title={"Documento"}>


        <div className="d-flex flex-column dx-theme-background-color rounded-3 p-3">
            {/* <div> */}
            <div className='p-0 m-0 h4 fw-bold'>Pré-Lançamento: Movimento Financeiro</div>
            <hr className='p-0 m-0 mb-2'></hr>


            <Row title={"Conta"} component={
                <SelectBox
                    value={contaValue}
                    onValueChanged={(e) => {
                        setContaValue(e.value)
                    }}
                    valueExpr={"id"}
                    displayExpr={"nome"}
                    searchEnabled={true}
                    items={dataConta}
                    showClearButton={true}
                />} />

            <Row title={"Tipo"} component={
                <SelectBox
                    className='mb-2'
                    value={tipoValue}
                    onValueChanged={(e) => setTipoValue(e.value)}
                    valueExpr={"id"}
                    displayExpr={"nome"}
                    items={dataTipo}
                    showClearButton={true}
                />} />

            <hr className='p-0 m-0'></hr>

            <Row title={"Entidade"} component={<SibeSelectBox
                id="entidade" className={"me-2 flex-fill"}
                label={""}
                textField="razao_social_nome"
                data={dataEntidade}
                value={entidadeValue}
                onChange={(value) => {
                    console.log(value)
                    setEntidadeValue(value)
                    setEntidadeId(value.id)
                }}
                required={true}
                dataGridComponent={Entidade}
            />} />

            <Row title={"Emissão"} component={
                <DateBox id="emissao"
                    onChange={(e) => setEmissaoValue(e)}
                    value={emissaoValue}
                    required
                />
            } />

            <Row title={"Valor"} component={
                <NumberBox id="valor"
                    showSpinButtons={false}
                    format="#,##0.00"

                    required
                    onChange={(e) => setValorValue(e)}
                    value={valorValue}
                />
            } />

            <Row title={"OBS Extrato"} component={
                <SibeTextBox id="observacao_extrato"
                    onChange={(e) => setObsValue(e)}
                    value={obsValue}
                />
            } />


            <div>
                <button
                    className='btn btn-primary'
                    onClick={async () => {
                        const URL = '/financeiro/documento/create_movimento'

                        if (entidadeId && emissaoValue && contaValue && tipoValue && valorValue) {


                            const contaObj = GetObjectById('id', dataConta, contaValue)


                            const vencimento = setDataVencimento({}, contaObj, emissaoValue) ?? emissaoValue

                            console.log(vencimento)

                            const pre = {
                                cad_entidade_id: entidadeId,
                                cad_fin_documento_tipo_id: 1,
                                emissao: emissaoValue,


                                cad_financeiro_conta_id: contaValue,
                                tipo: tipoValue,
                                valor: valorValue,
                                vencimento: vencimento,
                                observacao_extrato: obsValue


                            }

                            const resAPI = await api.post(URL, pre)




                            if (resAPI.isOk) {
                                showMessage("success", `Pré-Documento ${resAPI.data.instanceDocumento.id} incluído.`)
                                setEntidadeId()
                                setEntidadeValue()
                                setValorValue()
                                setEmissaoValue()
                                setObsValue()
                            }

                        } else {
                            showMessage("ERROR", "Verifique se todos os campos estão preenchidos.")
                        }
                    }}>
                    INCLUIR
                </button>
            </div>

        </div>


    </DefaultContainer>
}