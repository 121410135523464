import React from "react"

import { alert, confirm, custom } from "devextreme/ui/dialog"


export const dxConfirm = async (message) => {
    const messageHtml = `
        <div id="dx-confirm-content" >
            <span className="m-4">${message}</span>
        </div>
    `
    const res = await confirm(messageHtml, 'Confirmação necessária')
    return res
}



export const showMessage = (type, message, returnValue = false) => {
    let typeText = 'ERRO'

    let titulo = document.getElementById('titulo')
    titulo.classList.remove('text-danger');


    if (type == 'error') {
        typeText = 'ERRO'
        document.getElementById('messageModalTitle').innerText = 'ERRO'
        titulo.classList.add('text-danger')
    }

    if (type == 'success') {
        typeText = 'Sucesso'
        document.getElementById('messageModalTitle').innerText = 'Sucesso'
        titulo.classList.add('text-success')
    }

    document.getElementById('messageModalText').innerHTML = message
    var modal = new bootstrap.Modal(document.getElementById('modalMessages'))
    modal.show()

    return returnValue
}

export default () => {

    return <div
        id="modalMessages"
        className="modal fade"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel" aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header" >
                    <div className="d-flex align-items-center text-danger py-0 my-0" id="titulo">
                        <i className="fas fa-times fa-2xl me-2"></i>
                        <h5 className="modal-title" id="messageModalTitle">ERRO</h5>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <span id="messageModalText" className="dx-theme-accent-as-text-color"></span>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                        onClick={() => {
                            // document.getElementsByClassName('modal-backdrop fade show')

                            const elementos = document.querySelectorAll('.modal-backdrop.fade.show');
                            elementos.forEach(elemento => elemento.remove());


                            // console.log('closeModal')

                            // var myModalEl = document.getElementById('modalMessages');
                            // var modal = bootstrap.Modal.getInstance(myModalEl)
                            // modal.hide();

                        }}

                    >OK</button>
                </div>
            </div>
        </div>
    </div>
}