import React from 'react'
import { showMessage } from '../../../../components/template/Messages/Messages'
import { Button } from 'devextreme-react/button'
import { getParamsData } from './Params/utilsAPI'
import { getContaParams } from './utilsAPI'
import { startOfDay } from 'date-fns';

export const onRowPrepared = async (e) => {

    //  console.log(e)
    // console.log(e)


    if (e.rowType == 'data') {

        e.rowElement.style.fontWeight = 'bold'

        const data = e.data
        let isIncomplete = false
        const valor = data.valor || 0
        const valorTotal = data.v_valor_total || 0
        const valorTotalCompensado = data.valor_total_compensado || 0

        if (!data.tipo) isIncomplete = true
        if (!data.data_emissao) isIncomplete = true
        if (!data.data_vencimento) isIncomplete = true
        if (!data.data_compensacao) isIncomplete = true
        if (!data.cad_financeiro_conta_id) isIncomplete = true
        if (valor <= 0) isIncomplete = true
        if (valorTotal <= 0) isIncomplete = true
        if (valorTotalCompensado <= 0) isIncomplete = true


        const cellIndex = 6

        if (e.cells[cellIndex]) {
            if (data.tipo == 1) e.cells[cellIndex].cellElement.style.color = '#DC3545'
            if (data.tipo == 2) e.cells[cellIndex].cellElement.style.color = '#198754'
        }

        if (isIncomplete) {
            try {
                e.cells[0].cellElement.style.backgroundColor = '#d5e30f'
                e.cells[0].cellElement.style.color = '#cf4b1b'
            } catch (error) {

            }

        }

    }
}



export const ConfigButton = props => {
    return <Button icon="fas fa-cogs" stylingMode="contained"
        onClick={async () => {
            props.setPopupParamsVisible(true)
        }}
    />
}

export const DeleteButton = props => {
    return <Button icon="trash" stylingMode="contained"

        onClick={async () => {
            const dataGridInstance = props.gridRef.current.instance
            const SelectedRowKeys = dataGridInstance.getSelectedRowKeys()

            if (SelectedRowKeys.length < 1) return showMessage('error', 'Selecione um ou mais registro.')

            for (const key of SelectedRowKeys) await props.dataSource(props.grupoId).remove(key)
            await dataGridInstance.refresh()
            props.updateSummary(props.grupoId, props.setSummary)
        }}
    />
}

export const CloneButton = props => {
    return <Button icon="fa-solid fa-clone" stylingMode="contained"
        onClick={async () => {
            const dataGridInstance = props.gridRef.current.instance

            const SelectedRowsData = dataGridInstance.getSelectedRowsData()
            if (SelectedRowsData.length > 1) return showMessage('error', 'Selecione apenas um registro.')
            if (SelectedRowsData.length < 1) return showMessage('error', 'Selecione um registro.')

            let cloneReg = SelectedRowsData[0]
            delete cloneReg.id
            delete cloneReg.v_valor_total
            await props.dataSource(props.grupoId).insert(cloneReg)
            await dataGridInstance.refresh()
            props.updateSummary(props.grupoId, props.setSummary)
        }}
    />
}

export const setDataVencimento = (paramsData, contaData, grupoEmissao) => {
    let vencimento = new Date(grupoEmissao)
    vencimento = startOfDay(vencimento);

    if (contaData.tipo == 3 && grupoEmissao) {
        if (vencimento.getDate() > contaData.cartao_credito_fechamento) {
            vencimento.setMonth(vencimento.getMonth() + 1)
        }
        vencimento.setDate(contaData.cartao_credito_vencimento)
        paramsData.vencimento = vencimento
    }
    return String(vencimento)
}

export const AddButton = props => {
    return <Button type="button" icon="fa-solid fa-plus" onClick={async () => {
        const dataGridInstance = props.gridRef.current.instance
        let paramsData = await getParamsData()

        paramsData.vencimento = props.grupoEmissao
        paramsData.grupoEmissao = props.grupoEmissao

        if (paramsData.default_conta_id) {
            const contaData = await getContaParams(paramsData.default_conta_id)
            setDataVencimento(paramsData, contaData, props.grupoEmissao)
        }

        dataGridInstance.paramsData = paramsData
        dataGridInstance.addRow()
    }} />
}

export const onInitNewRow = async (row, grupoId) => {
    const paramsData = row.component.paramsData
    row.data.parcela = 1

    // Datas
    row.data.data_emissao = paramsData.grupoEmissao
    row.data.data_vencimento = paramsData.vencimento
    row.data.data_compensacao = paramsData.grupoEmissao

    // Conta
    if (paramsData.default_conta_id) row.data.cad_financeiro_conta_id = paramsData.default_conta_id
    if (paramsData.default_tipo_id) row.data.tipo = paramsData.default_tipo_id


    // Totais
    if (document.getElementById("produto-servico-total-valor-value" + grupoId)) {
        const total = document.getElementById("produto-servico-total-valor-value" + grupoId).value
        row.data.valor = total
        if (paramsData.default_compensado == 1) row.data.valor_total_compensado = total
    }
}