import React from 'react'
import SibeDatagrid from '../../components/DataGrid/DataGrid'

export default () => {

    const title = "Cadastro de Centro de Custo"
    const apiURL = "/cadastro/centro_de_custo"
    const columnItems = [
        { caption: "Nome", dataField: "nome", dataType: "string", width: "350", sortOrder: "asc" },
        { caption: "Proprietário", dataField: "proprietario_nome", dataType: "string", allowEditing: false },
        // { caption: "Compartilhado", dataField: "compartilhado", width: "150", lookup: true, dataSource: [{id: 0, nome: 'Não'}, {id: 1, nome: 'Sim'}], valueExpr: "id", displayExpr: "nome", allowEditing: true, allowFiltering: true },
        { caption: "Compartilhado", dataField: "compartilhado", dataType: "boolean" },


    ]

    return <SibeDatagrid columnItems={columnItems} apiURL={apiURL} title={title}
        showRefreshButton={true}
    />
}