import React, { useRef } from 'react';
import { Toolbar, Item, Paging, ColumnChooser, Summary, Position, GroupItem, Grouping, GroupPanel, TotalItem, DataGrid, Column, Selection, Scrolling, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { DefaultContainer } from '../../../components/template/Main';
import DxDataSource from 'devextreme/data/data_source';
import { Button } from 'devextreme-react/button'
import { api } from '../../../api/datasource';



const CustoDataSource =  new DxDataSource({    
    load: async (options) => {
        const res = await api.get('/relatorio/custo_tabela')
        return res
    },
})

export const CustoTabela = props => {
    const gridRef = useRef()
    // const [dataRows, setDataRows] = useState([])

    // useEffect(() => {
    //     const load = async () => upDateDataGridRows()
    //     load()
    // }, []);

    // const upDateDataGridRows = async () => {
    //     const data = await api.get(URL)
    //     setDataRows(data.data)
    // }

    return (<DefaultContainer title={'Relatório de Custos (Tabela)'}>
        <DataGrid className=""
            id="grid-relatorio-custo-tabela"
            ref={gridRef}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            dataSource={CustoDataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            height="85vh"
            width="100%"
        // hoverStateEnabled={true}
        >

            {/* TOOLBAR */}
            <Toolbar wordWrapEnabled={true}>
                <Item location="before" text={'Relatório de Custos (Tabela)'} />
                <Item name="groupPanel" />
                <Item location="after">
                    <Button icon="fas fa-sync-alt" stylingMode="contained"
                        onClick={async () => {
                            const dataGridInstance = gridRef.current.instance
                            await dataGridInstance.refresh()
                        }}
                    />

                </Item>
                {/* <Item name="addRowButton" />
                <Item name="saveButton" />
                <Item name="revertButton" /> */}
                <Item name="columnChooserButton" />

                <Item name="searchPanel" />


            </Toolbar>


            <ColumnChooser enabled={true}
                height={"82vh"} width={300}
                allowSearch={true}
            >
                {/* <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                /> */}
            </ColumnChooser>


            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging enabled={false} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />


            <Column dataField="sacado_nome" caption="Sacado" visible={true} width="250" groupIndex={0} autoExpandGroup={false} />
            <Column dataField="emissao_ano_mes" caption="Ano-Mês" visible={true} groupIndex={0} autoExpandGroup={false} />
            <Column dataField="item_classificacao" caption="Classificação" groupIndex={0} visible={true} />
            <Column dataField="emissao_emitente_nome" caption="Emissão - Emitente" visible={true} autoExpandGroup={false} />
            {/* <Column dataField="emissao_emitente_nome_tipo" caption="Emissão - Emitente" visible={true} autoExpandGroup={false} /> */}
            <Column dataField="item_produto_servico" caption="Produto/Serviço" visible={true} />
            {/* <Column dataField="item_unidade_codigo" caption="Un." width="80" visible={true} alignment="center" />
            <Column dataField="item_quantidade" caption="QTD" width="80" visible={true} alignment="center" />
            <Column dataField="item_valor_unitario" caption="Valor Un." width="100" visible={true} alignment="center" /> */}

            <Column dataField="item_valor_total" caption="Valor Total" width="120" visible={true} alignment="center">
                <HeaderFilter groupInterval={100} />
            </Column>
            <Column dataField="item_valor_total_percent" caption="Valor Total (Percent)" width="120" visible={true} alignment="center" />

            <Column dataField="item_centro_custo" caption="Centro de Custo" visible={true} width="250" />
            <Column dataField="documento_tipo_nome" caption="Tipo" visible={false} width="250" />

            <Summary>
                <TotalItem alignByColumn
                    valueFormat={{ type: "fixedPoint", precision: 2 }}
                    column="item_valor_total" summaryType="sum" />
                <TotalItem alignByColumn
                    valueFormat={{ type: "fixedPoint", precision: 2 }}
                    column="item_valor_total_percent" summaryType="sum" />
                <GroupItem
                    valueFormat={{ type: "fixedPoint", precision: 2 }}
                    alignByColumn column="item_valor_total" displayFormat="{0}" summaryType="sum" />
                <GroupItem
                    valueFormat={{ type: "fixedPoint", precision: 2 }}
                    alignByColumn column="item_valor_total_percent" displayFormat="{0}" summaryType="sum" />
            </Summary>





        </DataGrid>
    </DefaultContainer>
    )
}