import React, { useEffect, useRef, useState, forwardRef } from 'react'
import { Popup } from 'devextreme-react/popup'
import DataGrid, { Toolbar, Item, Selection, Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, } from 'devextreme-react/data-grid';
import TextBox from 'devextreme-react/text-box';



const ToolbarGrid = props => {
    return <div id={"selectbox-toolbar"} className="row justify-content-end mx-0 p-2">
        <div className="col-sm-2 d-grid gap-2">
            <button className="btn btn-dark btn-sm"
                onClick={() => props.setVisible(false)}
                type="button">Cancelar
            </button>
        </div>
        <div className="col-sm-2 d-grid gap-2">
            <button
                onClick={() => props.onClickSelecionar()}
                className="btn btn-primary btn-sm" type="button">Selecionar
            </button>
        </div>
    </div>
}


export const SelectBox = props => {
    const [displayExpr, setDisplayExpr] = useState()
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (props.value) {
            setDisplayExpr(props.value[props.textField])
        } else {
            setDisplayExpr("")
        }

    }, [props.value])

    return <div id={props.id} className={props.className}>
        <div className="d-flex">
            <div className="p-0 flex-grow-1">
                <TextBox
                    value={displayExpr}
                    label={props.label}
                    labelMode={"static"}
                    stylingMode={"underlined"}
                    onFocusIn={() => setVisible(true)}
                    validationStatus={!displayExpr && props.required == true ? "invalid" : "valid"}
                />

            </div>
        </div>
        <SelectBoxPopupUpGrid
            data={props.data}
            label={props.label}
            visible={visible}
            setVisible={setVisible}
            setDisplayExpr={setDisplayExpr}
            onChange={props.onChange}
            textField={props.textField}
            dataGridComponent={props.dataGridComponent}
            columnItems={props.columnItems}
        />
    </div>
}


const SelectBoxPopupUpGrid = props => {

    return <React.Fragment>
        <Popup
            // id="cadastro-popup"
            visible={props.visible}
            onHiding={() => props.setVisible(false)}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={false}
            showTitle={false}
            title={props.label}
            container=".dx-viewport"
            shading={true}
            width={"80vw"}
            // height={"55vh"}
            height={"auto"}
            enableBodyScroll={true}
        >
            <SelectBoxGrid
                data={props.data}
                title={props.label}
                setDisplayExpr={props.setDisplayExpr}
                visible={props.visible}
                setVisible={props.setVisible}
                onChange={props.onChange}
                textField={props.textField}
                dataGridComponent={props.dataGridComponent}
                columnItems={props.columnItems}
            />

        </Popup>
    </React.Fragment>
}

const SelectBoxGrid = props => {
    const gridRef = useRef()

    const onClickSelecionar = async () => {
        try {
            const dataGridInstance = gridRef.current.instance
            const data = await dataGridInstance.getSelectedRowsData()[0]

            if (data) {
                props.setDisplayExpr(data[props.textField])
                props.onChange(data)
                props.setVisible(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (props.dataGridComponent) {
        return <div>
            <props.dataGridComponent
                forwardRef={gridRef}
                hidePageContainer={true}
                height={'47vh'}
                onRowDblClick={onClickSelecionar}
                title={props.title}
            />
            <ToolbarGrid onClickSelecionar={onClickSelecionar} setVisible={props.setVisible} />
        </div>

    }
    const columnItems = props.columnItems || []
    return <div className="p-0 m-0">
        <DataGrid
            ref={gridRef}
            dataSource={props.data}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            onRowDblClick={onClickSelecionar}
            height={'47vh'}
        >
            <Selection mode="single" />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={false} />
            <Toolbar>
                <Item location="before" text={props.title} />
                {/* <Item location="before"><h4>{props.title || "NULL"}</h4></Item> */}
                <Item name="searchPanel" />
            </Toolbar>

            {columnItems.map((item, index) => {
                return <Column
                    key={index}
                    caption={item.caption || "null"}
                    dataField={item.dataField}
                    dataType={item.dataType || ""}
                    width={item.width}
                    sortOrder={item.sortOrder}
                    minWidth={item.minWidth}
                    allowEditing={item.allowEditing ?? true}
                    alignment={item.alignment || "left"}
                    groupIndex={item.groupIndex}
                    allowFiltering={item.allowFiltering ?? true}
                >
                    {/* {item.allowHeaderFiltering && <HeaderFilter visible={true} />} */}
                    {/* {item.lookup && <Lookup dataSource={item.dataSource} valueExpr={item.valueExpr} displayExpr={item.displayExpr} />} */}
                    {/* {item.formatType && <Format type={item.formatType} precision={item.formatPrecision} />} */}
                </Column>
            })}

        </DataGrid>
        <ToolbarGrid onClickSelecionar={onClickSelecionar} setVisible={props.setVisible} />
    </div>
}