import { api } from '../../../../api/datasource'


import { RoutesAPP } from '../../../../config/main/RoutesAPP'
import { RoutesAPI } from '../../../../config/main/RoutesAPI'
import { dxConfirm } from '../../../../components/template/Messages/Messages'



const DocumentoURL = RoutesAPI.financeiro.documento.base
const DocumentoGrupoURL = RoutesAPI.financeiro.documento._grupo.base

export const Save = async (key, value, uuid, navigate, loaded) => {

    let documento = {}
    documento[key] = value

    if (uuid) {
        // console.log('updating...')
        await api.put(DocumentoURL, uuid, documento)
        // console.log('updated!')
    }
    if (!uuid) {
        // console.log('creating...')
        const resAPI = await api.post(DocumentoURL, documento)

        // console.log('created!')
        if (resAPI.isOk) {
            await navigate(RoutesAPP.Financeiro.Documentos.index + "/" + resAPI.data.instance.uuid)
            location.reload()
        }
    }
}

export const Delete = async (uuid, navigate) => {
    if (uuid) {
        const confirm = await dxConfirm('ATENÇÃO! Deseja deletar o documento selecionado?')

        if (confirm) {
            const resAPI = await api.delete(DocumentoURL + "/" + uuid)
            if (resAPI.isOk) navigate(RoutesAPP.Financeiro.Documentos.index)
        }
    }
}

export const Load = async (uuid, navigate) => {
    if (uuid) {
        const resAPI = await api.get(DocumentoURL + "/" + uuid)
        if (resAPI.isOk) {
            return resAPI.data
        } else {
            console.log('Documento não localizado!')
            navigate(RoutesAPP.Financeiro.Documentos.index)
        }
    }
}

export const LoadAll = async () => {
    const resAPI = await api.get(DocumentoURL)

    if (resAPI.isOk) {
        return resAPI.data
    }
}

export const AddGroup = async (uuid, emissao, grupos) => {
    if (uuid) {
        const grupo = {
            referencia_ano: new Date(emissao).getFullYear(),
            referencia_mes: new Date(emissao).getMonth() + 1,
            emissao: grupos.length == 0 ? emissao : null
        }
        const resAPI = await api.post(DocumentoGrupoURL + "/" + uuid, grupo)
        return resAPI.data
    }
}

export const LoadGroups = async (uuid) => {
    if (uuid) {
        const resAPI = await api.get(DocumentoGrupoURL + "/" + uuid)
        return resAPI.data
    }
}