import { api } from '../../../../api/datasource'
import CustomStore from 'devextreme/data/custom_store'

const baseURL = "/financeiro/documento_grupo_movimentacao/"

export const getContaParams = async (contaId) => {
    try {
        const params = await api.get("/cadastro/financeiro_conta/" + contaId)
        return params.data
    } catch (error) {
        console.error(error)
        return {}
    }
}

export const updateSummary = async (grupoId, setSummary) => {
    const apiURL = baseURL + grupoId + "/summary"
    const res = await api.get(apiURL)
    setSummary(res.data)
}

export const dataSource = (grupoId, setSummary) => {
    const apiURL = baseURL + grupoId

    return new CustomStore({
        key: 'id',
        load: async (options) => {
            const res = await api.get(apiURL)
            return res
        },
        insert: async (values) => {
            values.fin_documento_grupo_id = grupoId
            const res = await api.post(apiURL, values)

            if (res.isOk == false) {
                console.log(res)
                console.log(res.message) 
                throw new Error(res.message)
            }            
        },

        update: async (obj, values) => {
            const res = await api.put(apiURL, obj, values)
            return res.data
        },

        remove: async (key) => {
            await api.delete(apiURL + '/' + key)
        },

        byKey: async (key) => {
            console.error('ATENÇÃO FUNÇÃO BY KEY NÃO IMPLEMENTADA')
            console.log(key)
        }
    })

}