import { api } from '../../api/datasource'
import DataSource from 'devextreme/data/data_source';

export const dataSource = (apiURL, apiURLPUT = apiURL) => new DataSource({





    load: async (options) => {

        // console.log(options)

        const res = await api.get(apiURL)
        return res
    },
    remove: async (key) => await api.delete(apiURL + '/' + key.id),
    update: async (key, values) => {
        await api.put(apiURLPUT, key.id, values)
    },
    insert: async (values) => await api.post(apiURL, values),
    byKey: async (key) => {
        console.error('ATENÇÃO FUNÇÃO BY KEY NÃO IMPLEMENTADA')
        console.log(key)
    }
})