import React from 'react'
import Header from './Header'
import { MainTitle } from '../../components/template/MainTitle'
import './Main.css'

export const DefaultContainer = props => {
    if (!props.hidePageContainer) document.title = 'MyMoney - ' + props.title ?? ""

    if (props.hidePageContainer) return props.children

    return <React.Fragment>
        <Header />
        <div id="main-container" className="container-fluid py-4 px-4">
            {props.children}
        </div>
    </React.Fragment>
}