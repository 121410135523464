import React, { useEffect, useState } from 'react'
import SibeDatagrid from '../../../components/DataGrid/DataGrid'
import { LoadCadastro } from '../documentos/utils/LoadCadastro'
import { SelectBox } from 'devextreme-react'

const cellRenderId = (cell) => <a href={'/financeiro/documentos/' + cell.data.uuid} target="_blank">{cell.data.documento_id}</a>




const observacaoCellRender = (cell) => {

    let text = cell.row.data.observacao

    if (cell.row.data.pre_documento === 1) {
        text = <div>
            <i className="fas fa-exclamation-circle text-danger fw-bold me-1 fa-lg"></i>
            <i className="fas fa-exclamation-circle text-warning fw-bold me-1 fa-lg"></i>
            <i className="fas fa-exclamation-circle text-danger fw-bold me-1 fa-lg"></i>
            <i className="fas fa-exclamation-circle text-warning fw-bold me-1 fa-lg"></i>
            <i className="fas fa-exclamation-circle text-danger fw-bold me-2 fa-lg"></i>
            PRÉ-DOCUMENTO SEM PRODUTOS/SERVIÇOS - {cell.row.data.observacao}
        </div>
    }

    return <div>{text}</div>
}

const observacaoExtratoCellRender = (cell) => {

    const text = cell.row.data.observacao_extrato;

    // Regex para detectar links
    const urlPattern = /^(https?:\/\/[^\s]+)/;

    // Verifica se o texto é um link
    return urlPattern.test(text) ? (
        <div><a className="p-0" href={text} target="_blank" rel="noopener noreferrer">{text}</a></div>
    ) : (
        <div>{text}</div>
    );
}

const items = [{ id: '30d', nome: 'Últimos 30 dias' }, { id: '3m', nome: 'Últimos 3 meses' }, { id: '6m', nome: 'Últimos 6 meses' }, { id: '1a', nome: 'Último ano' }, { id: 'all', nome: 'Todo o período' }]

const baseApiURL = '/financeiro/extrato?periodo='
const apiURLPUT = '/financeiro/extrato'

export const Extrato = () => {
    const [dataCadastro, setDataCadastro] = useState([])
    const [periodoValue, setPeriodoValue] = useState(items[2].id)
    const [apiURL, setApiURL] = useState(`${baseApiURL}${periodoValue}`)

    const ComponentToolBar = () => {
        return <SelectBox
            value={periodoValue}
            onValueChanged={(e) => {                
                setPeriodoValue(e.value)
                setApiURL(`${baseApiURL}${e.value}`)
            }}
            defaultValue={items[2]}
            valueExpr={"id"}
            displayExpr={"nome"}
            items={items}
        />

    }


    const RefreshCadastro = async () => {

        try {
            const dataFinanceiroContaTipo = await LoadCadastro('/cadastro/financeiro_movimentacao_tipo')
            const dataFinanceiroConta = await LoadCadastro('/cadastro/financeiro_conta')
            const dataEntidade = await LoadCadastro('/cadastro/entidade')
            setDataCadastro({
                financeiro_conta_tipo: dataFinanceiroContaTipo,
                financeiro_conta: dataFinanceiroConta,
                entidade: dataEntidade,
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => { RefreshCadastro() }, [])

    const title = "Extrato"
    const columnItems = [

        { caption: "Conta", dataField: "cad_financeiro_conta_id", width: "150", lookup: true, dataSource: dataCadastro.financeiro_conta ?? [], valueExpr: "id", displayExpr: "nome", allowEditing: false, allowFiltering: true },
        { caption: "Entidade", dataField: "entidade_id", alignment: "left", width: "200", lookup: true, dataSource: dataCadastro.entidade ?? [], valueExpr: "id", displayExpr: "nome", allowEditing: false, allowFiltering: true },
        { caption: "Tipo", dataField: "tipo", alignment: "center", width: "100", lookup: true, dataSource: dataCadastro.financeiro_conta_tipo ?? [], valueExpr: "id", displayExpr: "nome", allowEditing: false, allowFiltering: true },
        { caption: "OBS", dataField: "observacao_extrato", dataType: "string", width: "100", cellRender: observacaoExtratoCellRender },
        { caption: "Check", dataField: "conferido", dataType: "boolean", alignment: "center", width: "50" },
        { caption: "Valor", dataField: "valor_total_compensado_com_negativo", dataType: "number", width: "100", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false, allowEditing: false },
        { caption: "Data", dataField: "data_compensacao", dataType: "date", alignment: "center", width: 100, sortOrder: "desc", allowEditing: false, allowFiltering: true },
        { caption: "Vcto", dataField: "data_vencimento", dataType: "date", alignment: "center", width: 100, allowEditing: false, allowFiltering: true },
        { caption: "Parcela", dataField: "parcela", dataType: "number", alignment: "center", width: 50, allowEditing: false },
        { caption: "Doc.", dataField: "documento_id", dataType: "number", alignment: "center", width: 80, allowEditing: false, allowFiltering: true, cellRender: cellRenderId },
        { caption: "Pré-Doc", dataField: "pre_documento", dataType: "boolean", alignment: "center", width: "50", allowEditing: false },

        { caption: "Observação", dataField: "observacao", dataType: "string", allowEditing: false, cellRender: observacaoCellRender },
        // { caption: "Pré-Documento", dataField: "pre_documento", dataType: "string", allowEditing: false, },
    ]

    const summaryItems = [
        { column: "valor_total_compensado_com_negativo", summaryType: "sum", type: "fixedPoint", precision: 2 },
    ]

    const onRowPrepared = async (e) => {
        const columnName = 'tipo';  // Substitua pelo nome da sua coluna        
        if (e.rowType == 'data') {
            const column = e.columns.find(col => col.dataField === columnName);
            const cellIndex = e.columns.indexOf(column);
            if (e.cells[cellIndex]) {
                if (e.data.tipo == 1) e.cells[cellIndex].cellElement.style.color = '#d14856'
                if (e.data.tipo == 2) e.cells[cellIndex].cellElement.style.color = '#198754'
            }
        }
    }

    return <SibeDatagrid

        columnItems={columnItems}
        apiURL={apiURL}
        apiURLPUT={apiURLPUT}
        title={title}
        allowAdding={false}
        allowDeleting={false}
        // ShowGroupPanel={true}
        hideColumnsDefault={true}
        showRefreshButton={true}

        // selectionMode={"multiple"}
        summaryItems={summaryItems}
        onClickRefresh={RefreshCadastro}
        onRowPrepared={onRowPrepared}
        ComponentToolBar={ComponentToolBar}
    />
}