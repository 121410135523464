import React, { useRef, useState, useEffect } from 'react'
import DataGrid, { Summary, TotalItem, Toolbar, Item, Selection, Paging, Scrolling, LoadPanel, Editing, Column, GroupPanel, Sorting, SearchPanel, Lookup, KeyboardNavigation, Format, } from 'devextreme-react/data-grid'
import { DeleteButton, CloneButton, CadastroButton, updateSummary } from './utilsUI'
import { dataSource } from './utilsAPI'
import { dataGridColumns } from './dataGridColumns'
import { Popup } from '../../../../components/Popup/Popup'
import CadastroProdutosServicos from '../../../cadastro/ProdutoServico'
import { DropDownOptions } from 'devextreme-react/lookup'


export const ProdutosServicos = props => {
    const gridRef = useRef()
    const [popupVisible, setPopupVisible] = useState(false)
    const [localDataSource, setLocalDataSource] = useState([])

    useEffect(() => {
        const load = async () => {
            setLocalDataSource(dataSource(props.grupoId))
        }
        load()
    }, [])

    return <div className={'row mx-0 ' + props.className}>

        <Popup
            visible={popupVisible}
            setVisible={setPopupVisible}
            // onClose={''}
            onClickOk={props.loadDataCadastro}
            content={<CadastroProdutosServicos hidePageContainer={true} height={'47vh'} />}
        />
        <DataGrid
            keyExpr="id"
            ref={gridRef}
            id={"gridProdutoServico" + props.grupoId}
            dataSource={localDataSource}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            rowAlternationEnabled={false}
            allowColumnReordering={false}
            allowColumnResizing={true}
            onEditorPreparing={(e) => e.editorOptions.dropDownOptions = { width: 400 }}

            onCellDblClick={(e) => {

                if (e.rowType === "data" && e.column.name === "valor_total") {
                    

                }

            }}

            // : DoubleClickCellValueValorTotal,
            hoverStateEnabled={true}
            repaintChangesOnly={false}
            activeStateEnabled={true}

            onSaved={() => updateSummary(gridRef, props.grupoId)}
            onCellHoverChanged={() => updateSummary(gridRef, props.grupoId)}
            onContentReady={() => updateSummary(gridRef, props.grupoId)}

        >
            <Toolbar>
                <Item location="before" text={"Produtos/Serviços"}></Item>
                <Item location="after" name="addRowButton" options={{ stylingMode: "contained" }} />
                <Item location="after" name="saveButton" options={{ stylingMode: "contained" }} />
                <Item location="after"><div className="vr mx-2"></div></Item>
                <Item location="after">
                    <CloneButton gridRef={gridRef} dataSource={localDataSource} grupoId={props.grupoId} />
                </Item>
                <Item location="after">
                    <DeleteButton gridRef={gridRef} dataSource={localDataSource} grupoId={props.grupoId} />
                </Item>
                <Item location="after" name="revertButton" options={{ stylingMode: "contained" }} />
                <Item location="after"><CadastroButton setPopupVisible={setPopupVisible} /></Item>
            </Toolbar>

            <Selection mode="multiple" showCheckBoxesMode={"always"} />
            <Editing
                mode="batch" //cell
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={false}
                // refreshMode={"repaint"}
                selectTextOnEditStart={false}
                startEditAction={'click'}
                useIcons={true}
                newRowPosition={'last'}
            />

            <KeyboardNavigation editOnKeyPress={true} />
            <Paging enabled={false} />
            <Scrolling mode="standard" useNative={true} />
            <LoadPanel enabled={true} />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Sorting mode={'none'} />

            {/* Colunas */}

            {dataGridColumns(props.dataCadastro).map((item, index) => <Column
                key={index}
                caption={item.caption}
                dataField={item.dataField}
                dataType={item.dataType}
                width={item.width}
                minWidth={item.minWidth}
                allowEditing={item.allowEditing}
                alignment={item.alignment}
                // defaultSortOrder={item.defaultSortOrder}
                fixed={item.fixed} name={item.name}
                visible={item.visible}
                // sortOrder={item.sortOrder}
                calculateCellValue={item.calculateCellValue}
                fixedPosition={item.fixedPosition}
                calculateDisplayValue={item.calculateDisplayValue}
                allowClearing={item.allowClearing}
            >
                {item.lookup &&
                    <Lookup
                        searchEnabled={false}
                        dataSource={{
                            store: {
                                data: item.dataSource,
                                type: 'array',
                                key: 'id'
                            },
                            // select: ['nome_full'],
                            // pushAggregationTimeout: 20,
                            paginate: true,
                            pageSize: 250

                        }}
                        valueExpr={item.valueExpr}
                        displayExpr={item.displayExpr}
                    >

                        <DropDownOptions
                            hideOnOutsideClick={true}
                            showTitle={true}
                        />

                    </Lookup>
                }

                {item.formatType && <Format type={item.formatType} precision={item.formatPrecision} />}

            </Column>
            )}
            <Column type="selection" width={55} fixed={true} fixedPosition={"right"} />
        </DataGrid>

        <div className="d-flex fw-bold rounded-bottom mx-0 px-0 pt-2">
            <div className="px-2">Items: <span id={"produto-servico-total-item-label" + props.grupoId}></span></div>
            <div className="px-2 text-primary">Total: <span id={"produto-servico-total-valor-label" + props.grupoId}>0,00</span> </div>


            <input id={"produto-servico-total-item-value" + props.grupoId} className="invisible" readOnly value={0} />
            <input id={"produto-servico-total-valor-value" + props.grupoId} className="invisible" readOnly value={0} />
        </div>

    </div>

}