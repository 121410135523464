import React from 'react'
import { Popup as DxPopup } from 'devextreme-react/popup'

export const Popup = props => {
    return <React.Fragment>
        <DxPopup
            visible={props.visible}
            onHiding={() => props.setVisible(false)}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={false}
            showTitle={false}
            title={props.label}
            container=".dx-viewport"
            shading={true}
            width={props.width || "80vw"}
            // height={props.height || "70vh"}
            height={"auto"}
            enableBodyScroll={true}
            onShown={props.onShown}
        >
            {props.content}

            <div id={"selectbox-toolbar"} className="row justify-content-end mx-0 p-2">
                <div className="col-sm-2 d-grid gap-2">
                    <button className="btn btn-primary btn-sm"
                        onClick={async () => {                            
                            if (props.onClickOk) await props.onClickOk()
                            props.setVisible(false)                            
                        }}
                        type="button">OK
                    </button>
                </div>
            </div>

        </DxPopup>
    </React.Fragment>
}