import React, { useRef, useEffect, useState } from 'react'
import DataGrid, {
    Scrolling, GroupPanel, Pager, Paging, Column, FilterRow, HeaderFilter, Search, SearchPanel,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { api } from './api/datasource';


const apiUrl = '/financeiro/extrato'

// Função para construir a URL com parâmetros de filtros e pesquisa
const buildQueryParams = (loadOptions) => {
    let params = [];

    if (loadOptions.filter) {
        const [field, operator, value] = loadOptions.filter;
        if (field === 'data_compensacao') {
            params.push(`${field}=${value}`);
        }
    }

    if (loadOptions.searchValue) {
        params.push(`search=${loadOptions.searchValue}`);
    }

    return params.length > 0 ? '?' + params.join('&') : '';
};


const customStore = new CustomStore({
    key: 'id',
    // loadMode:
    load: (loadOptions) => {
        console.log(loadOptions)
        const res = api.get(apiUrl)
        return res
    },

    // totalCount: (loadOptions) => {
    //     console.log(loadOptions)
    //     return 10
    // }

});


export default props => {

    return <DataGrid
        dataSource={customStore}
        // remoteOperations={true}
        showBorders={true}
    >
        {/* <Paging defaultPageSize={10} />
        <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
        /> */}

        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Scrolling mode="infinite" />

        <Column dataField="id" caption="ID" width={70} />
        <Column dataField="data_compensacao" caption="Data de Compensação" dataType="date" width={150} />
        <Column dataField="documento_id" caption="Documento ID" />

    </DataGrid>
}