import React, { useEffect, useState, useRef } from 'react'
import SibeDatagrid from '../../../components/DataGrid/DataGrid'
import { LoadCadastro } from '../documentos/utils/LoadCadastro'
import SelectBox from 'devextreme-react/select-box'

export const LancamentoProdutoServico = () => {
    const ClassificacaoRef = useRef()
    const ProdutoRef = useRef()
    const GridRef = useRef()

    const [dataCadastro, setDataCadastro] = useState([])

    useEffect(() => {
        const asyncEffect = async () => {
            const dataFinanceiroProdutoServico = await LoadCadastro('/cadastro/produto_servico')
            const dataFinanceiroProdutoServicoClassificacao = await LoadCadastro('/cadastro/produto_servico_classificacao')
            const dataFinanceiroCentroCusto = await LoadCadastro('/cadastro/centro_de_custo')
            const dataUnidadeMedida = await LoadCadastro('/cadastro/unidade_de_medida')
            setDataCadastro({
                produto_servico: dataFinanceiroProdutoServico,
                produto_servico_classificacao: dataFinanceiroProdutoServicoClassificacao,
                centro_de_custo: dataFinanceiroCentroCusto,
                unidade_de_medida: dataUnidadeMedida,
            })
        }
        asyncEffect()
    }, [])


    const title = "Lançamentos de Produtos e Serviços"
    const apiURL = "/financeiro/lancamento/produto_servico"

    // {/* <Column type="selection" width={55} fixed={true} fixedPosition={"right"} /> */ }
    const columnItems = [

        // { type: "selection", width: "55", fixed: true, fixedPosition: "left" },
        { caption: "Classificação", dataField: "cad_produto_servico_classificacao_id", width: "320", lookup: true, dataSource: dataCadastro.produto_servico_classificacao ?? [], valueExpr: "id", displayExpr: "nome" },
        { caption: "Produto/Serviço", dataField: "cad_produto_servico_id", width: "280", lookup: true, dataSource: dataCadastro.produto_servico ?? [], valueExpr: "id", displayExpr: "nome_full" },
        { caption: "Centro de Custo", dataField: "cad_centro_custo_id", width: "150", lookup: true, dataSource: dataCadastro.centro_de_custo ?? [], valueExpr: "id", displayExpr: "nome" },
        { caption: "Un.", dataField: "cad_unidade_medida_id", width: "50", lookup: true, dataSource: dataCadastro.unidade_de_medida ?? [], valueExpr: "id", displayExpr: "codigo", alignment: "center", allowEditing: false },
        { caption: "Qtd", dataField: "quantidade", dataType: "number", width: "60", alignment: "center", formatType: "fixedPoint", formatPrecision: 3, allowEditing: false },
        { caption: "Valor", dataField: "valor_unitario", dataType: "number", width: "80", alignment: "center", formatType: "fixedPoint", formatPrecision: 3, allowEditing: false },
        { caption: "Desc", dataField: "valor_desconto", dataType: "number", width: "65", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: "Acres", dataField: "valor_acrescimo", dataType: "number", width: "65", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: "Total", dataField: "v_valor_total", dataType: "number", width: "100", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: "Observação", dataField: "observacao", dataType: "string", minWidth: "200", allowEditing: false },
    ]

    const Teste = props => {

        // if (true) return <></>

        return <div className="d-sm-flex my-4">
            <div className="d-flex flex-column ms-4 me-2 mt-4">
                <span>Mover classificações selecionadas para:
                    <button type="button" className="btn btn-link"
                        onClick={async () => {
                            if (confirm("ATENÇÃO, ESSA AÇÃO MODIFICA VÁRIOS REGISTROS DE UMA VEZ, DESEJA CONTINUAR?") == false) return
                            const dataGridInstance = GridRef.current.instance
                            const classificacaoInstance = ClassificacaoRef.current.instance
                            if (classificacaoInstance.option('value')) {
                                const selectedRowKeys = dataGridInstance.getSelectedRowKeys()
                                const dataSource = dataGridInstance.getDataSource()
                                for (const selectedRow of selectedRowKeys) {
                                    const values = { cad_produto_servico_classificacao_id: classificacaoInstance.option('value').id }
                                    console.log(selectedRow)
                                    await dataSource.store().update(selectedRow, values)
                                }
                                dataGridInstance.deselectAll()
                                dataGridInstance.refresh()
                            }
                        }}
                    >Confirmar</button>
                </span>
                <SelectBox ref={ClassificacaoRef} items={dataCadastro.produto_servico_classificacao} displayExpr={"nome"} displayValue={"id"} width={'350'} />
            </div>
            <div className="d-flex flex-column ms-4 mt-4">
                <span>Mover produtos/Serviços selecionados para:
                    <button type="button" className="btn btn-link"
                        onClick={() => {
                            if (confirm("ATENÇÃO, ESSA AÇÃO MODIFICA VÁRIOS REGISTROS DE UMA VEZ, DESEJA CONTINUAR?") == false) return
                            const dataGridInstance = GridRef.current.instance
                            const produtoInstance = ProdutoRef.current.instance
                            if (produtoInstance.option('value')) {
                                const selectedRowKeys = dataGridInstance.getSelectedRowKeys()
                                const dataSource = dataGridInstance.getDataSource()
                                selectedRowKeys.forEach(selectedRow => {
                                    const values = { cad_produto_servico_id: produtoInstance.option('value').id }
                                    dataSource.store().update(selectedRow, values)
                                })
                            }
                        }}
                    >Confirmar</button>
                </span>
                <SelectBox ref={ProdutoRef} items={dataCadastro.produto_servico} displayExpr={"nome_full"} displayValue={"id"} width={'350'} />
            </div>


        </div>
    }


    return <SibeDatagrid
        id={'grid-lancamento-produto-servico'}
        forwardRef={GridRef}
        columnItems={columnItems}
        apiURL={apiURL}
        title={title}
        allowAdding={false}
        allowDeleting={false}
        ShowGroupPanel={true}
        allowHeaderFiltering={true}
        showRefreshButton={true}
        selectionMode={"multiple"}
        afterComponent={Teste}
    />
}