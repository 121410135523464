const getCurrentTheme = () => {
  return localStorage.getItem('theme') ?? "light"
}

export const setTheme = () => {
  const currentTheme = getCurrentTheme()
  if (currentTheme == 'light') localStorage.setItem('theme', 'dark')
  if (currentTheme == 'dark') localStorage.setItem('theme', 'light')
  location.reload()
}

export const SwitchTheme = props => {
  const currentTheme = getCurrentTheme()
  if (currentTheme == 'dark') return <>
    <i className="me-2 fas fa-lightbulb" />
    Modo claro
  </>
  if (currentTheme == 'light') return <>
    <i className="me-2 fas fa-moon" />
    Modo escuro
  </>
}

// export const ThemeMode = (defaultValue = true) => {
//   return <IconTheme />
// }