import React from 'react'
import SibeDatagrid from '../components/DataGrid/DataGrid'

export default () => {

    const title = "Pendências do Sistema"
    const apiURL = "/cadastro/pendencias"
    const columnItems = [
        { caption: "Descrição", dataField: "descricao", dataType: "string" },

        


        {
            caption: "Autor", dataField: "sys_user_id", width: "150", alignment: "center",
            lookup: true,
            dataSource: [{ id: 1, nome: 'Paulo Precht' }, { id: 2, nome: 'Daniela Kessler' }],
            valueExpr: "id",
            displayExpr: "nome",
            allowEditing: false
        },


        {
            caption: "Tipo", dataField: "tipo", width: "150", alignment: "center",
            lookup: true,
            dataSource: [{ id: 1, nome: 'Problema' }, { id: 2, nome: 'Melhoria' }],
            valueExpr: "id",
            displayExpr: "nome"
        },

        {
            caption: "Status", dataField: "pendencia_status", width: "150", sortOrder: "asc", alignment: "center",
            lookup: true,
            dataSource: [{ id: 1, nome: 'Aberta' }, { id: 2, nome: 'Fechada' }],
            valueExpr: "id",
            displayExpr: "nome"
        },



    ]

    return <SibeDatagrid columnItems={columnItems} apiURL={apiURL} title={title} sortInclusao={'desc'} />
}