import React from 'react'
import SibeDatagrid from '../../components/DataGrid/DataGrid'

export default () => {

    const title = "Cadastro de Classificação de Produto/Serviço"
    const apiURL = "/cadastro/produto_servico_classificacao"
    const columnItems = [        
        { caption: "Nome", dataField: "nome", dataType: "string", minWidth: "120" },
        { caption: "Lançamentos", dataField: "lancamentos_quantidade", dataType: "number", width: "175", alignment: "center", allowFiltering: false, allowEditing: false },
        { caption: "Proprietário", dataField: "proprietario_nome", dataType: "string", allowEditing: false },
        { caption: "Compartilhado", dataField: "compartilhado", dataType: "boolean", alignment: "center" },
        
    ]

    return <SibeDatagrid columnItems={columnItems} apiURL={apiURL} title={title} showRefreshButton={true} />
}