import React, { useState, useEffect, useRef, useCallback } from 'react'
import SibeDatagrid from '../../components/DataGrid/DataGrid'
import { api } from '../../api/datasource'
export default props => {
    const [unidadeMedidaData, setUnidadeMedidaData] = useState([])

    useEffect(() => {
        const load = async () => {
            const res = await api.get('/cadastro/unidade_de_medida')

            setUnidadeMedidaData(res.data)
        }
        load()
    }, [])

    const title = "Cadastro de Produto/Serviço"
    const apiURL = "/cadastro/produto_servico"
    const columnItems = [        
        { caption: "Nome", dataField: "nome", dataType: "string", width: "250" },

        {
            caption: "Quantidade", dataField: "quantidade", dataType: "number", width: "110", alignment: "center",
            formatType: "fixedPoint",
            formatPrecision: 2
        },

        {
            caption: "Un. Medida", dataField: "cad_unidade_medida_id", width: "150",
            lookup: true,
            dataSource: unidadeMedidaData,
            valueExpr: "id",
            displayExpr: "nome"
        },
        { caption: "Lançamentos", dataField: "lancamentos_quantidade", dataType: "string", width: "175", alignment: "center", allowFiltering: true, allowEditing: false },

        { caption: "Proprietário", dataField: "proprietario_nome", dataType: "string", allowEditing: false },
        { caption: "Compartilhado", dataField: "compartilhado", dataType: "boolean", alignment: "center", width: "100" },
        

    ]

    return <SibeDatagrid
        columnItems={columnItems}
        apiURL={apiURL}
        title={title}
        hidePageContainer={props.hidePageContainer ?? false}
        height={props.height}
        showRefreshButton={true}
    />
}