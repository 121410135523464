import React, { useEffect, useState } from 'react'
import { NumberBox as NumberBoxDevExtreme } from 'devextreme-react/number-box';

export const NumberBox = props => {

    return <div className={props.className}>

        {/* <div className="d-flex"> */}
        {/* <div className="p-0 flex-grow-1"> */}
        <NumberBoxDevExtreme
            
            showSpinButtons={props.showSpinButtons ?? true}
            showClearButton={false}
            format={props.format}
            min={props.min}
            max={props.max}
            label={props.label}
            labelMode={"static"}
            stylingMode={props.stylingMode ?? "underlined"}
            validationStatus={!props.value && props.required == true ? "invalid" : "valid"}
            onValueChanged={(e) => {
                if (e.event) {

                    

                    props.onChange(e.value)
                }
            }}
            value={props.value}
            width={props.width}
        />
    </div >
    {/* </div> */ }
    {/* </div> */ }
}