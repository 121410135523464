import React from 'react'
import { RoutesAPP } from './RoutesAPP'
// import Home from '../pages/home/Home'
import { Entidade, CentroCusto, ProdutoServico, ProdutoServicoClassificacao, Conta } from '../../pages/cadastro/index'
import { FinanceiroIndex, DocumentoList, Documento, Saldos, LancamentoProdutoServico, Extrato, Params, CreateMovimento } from '../../pages/financeiro/index'
import { Routes, Route } from 'react-router-dom'
import { CustoPivot, CustoTabela } from '../../pages/relatorio'

import Pendencias from '../../pages/Pendencias'

import { Index } from '../../pages/home/Index'

export default () => {

    return (<Routes>
        <Route path='/home' element={<Index />} />
        <Route path={'/teste'} element={<Pendencias />} />
        <Route path={'/pendencias'} element={<Pendencias />} />

        {/* Cadastros */}
        <Route path={RoutesAPP.Cadastro.entidade} element={<Entidade />} />
        <Route path={RoutesAPP.Cadastro.centro_custo} element={<CentroCusto />} />

        <Route path={RoutesAPP.Cadastro.produto_servico} element={<ProdutoServico />} />
        <Route path={RoutesAPP.Cadastro.produto_servico_classificacao} element={<ProdutoServicoClassificacao />} />
        <Route path={RoutesAPP.Cadastro.financeiro_conta} element={<Conta />} />

        {/* Financeiro */}
        <Route path={RoutesAPP.Financeiro.index} element={<FinanceiroIndex />} />
        <Route path={RoutesAPP.Financeiro.Saldos} element={<Saldos />} />

        <Route path={RoutesAPP.Financeiro.Documentos.index} element={<DocumentoList />} />
        <Route path={RoutesAPP.Financeiro.Documentos.index + "/create"} element={<Documento mode={"create"} />} />
        <Route path={RoutesAPP.Financeiro.Documentos.index + "/:documentoId"} element={<Documento />} />
        
        <Route path={RoutesAPP.Financeiro.Documentos.index + "/create_movimento"} element={<CreateMovimento mode={"create"} />} />

        <Route path={RoutesAPP.Financeiro.lancamento_produto_servico} element={<LancamentoProdutoServico />} />
        <Route path={RoutesAPP.Financeiro.extrato} element={<Extrato />} />

        <Route path={RoutesAPP.Financeiro.parametros} element={<Params />} />


        <Route path={RoutesAPP.Relatorio.custo_pivot} element={<CustoPivot />} />
        <Route path={RoutesAPP.Relatorio.custo_tabela} element={<CustoTabela />} />

        {/* <Route path={AppRoutes.Financeiro.Documento.form} element={<Documento />} /> */}
        {/* <Route path={AppRoutes.Financeiro.Documento.form + "/:uuid"} element={<Documento />} /> */}


        <Route path={"*"} element={<Index />} />
    </Routes>)
}