import React from 'react'
import SibeDatagrid from '../../../components/DataGrid/DataGrid'

var getMonthYear = function (date, idx) {
    date.setMonth(date.getMonth() + idx)
    var locale = "pt-BR", month = date.toLocaleString(locale, { month: "long" })
    return month
}

export const Saldos = props => {
    const captionSaldoCredito1 = getMonthYear(new Date(), -1)
    const captionSaldoCredito2 = getMonthYear(new Date(), 0)
    const captionSaldoCredito3 = getMonthYear(new Date(), 1)
    const captionSaldoCredito4 = getMonthYear(new Date(), 2)
    const captionSaldoCredito5 = getMonthYear(new Date(), 3)
    const captionSaldoCredito6 = getMonthYear(new Date(), 4)

    const title = "Saldos"
    const apiURL = "/financeiro/saldos"
    const columnItems = [
        { caption: "Nome", dataField: "conta_nome", dataType: "string", width: "200", sortOrder: "asc", allowFiltering: true, allowEditing: false },
        { caption: "Disponível (Sibe)", dataField: "saldo_disponivel_sistema", dataType: "number", width: "130", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: "Disponível (Real)", dataField: "saldo_disponivel_real", dataType: "number", width: "130", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },
        { caption: "Disponível (Dif)", dataField: "saldo_disponivel_diferenca", dataType: "number", width: "130", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: "Crédito: Limite", dataField: "credito_limite", dataType: "number", width: "130", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: "Crédito: Usado", dataField: "credito_usado", dataType: "number", width: "130", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: captionSaldoCredito1, dataField: "saldo_credito_vcto_1", dataType: "number", width: "120", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: captionSaldoCredito2, dataField: "saldo_credito_vcto_2", dataType: "number", width: "120", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: captionSaldoCredito3, dataField: "saldo_credito_vcto_3", dataType: "number", width: "120", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: captionSaldoCredito4, dataField: "saldo_credito_vcto_4", dataType: "number", width: "120", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: captionSaldoCredito5, dataField: "saldo_credito_vcto_5", dataType: "number", width: "120", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: captionSaldoCredito6, dataField: "saldo_credito_vcto_6", dataType: "number", width: "120", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { caption: " ", dataField: "", dataType: "string", allowEditing: false },
    ]



    const summaryItems = [
        { column: "saldo_disponivel_sistema", summaryType: "sum", type: "fixedPoint", precision: 2 },
        { column: "saldo_disponivel_real", summaryType: "sum", type: "fixedPoint", precision: 2 },
        { column: "saldo_disponivel_diferenca", summaryType: "sum", type: "fixedPoint", precision: 2 },
    ]

    return <SibeDatagrid
        columnItems={columnItems}
        apiURL={apiURL}
        title={title}
        hideColumnsDefault={true}
        allowDeleting={false}
        allowAdding={false}
        showRefreshButton={true}

        summaryItems={summaryItems}
    />
}