import React, { useState, useEffect } from 'react'
import './financeiro-documento.css'
import { Load, Save, Delete, AddGroup, LoadGroups } from './utils/Documento';
import { SelectBox } from '../../../components/Editors/SelectBox'
import { DateBox } from '../../../components/Editors/DateBox'
import { TextBox } from '../../../components/Editors/TextBox'
import { useParams, useNavigate } from 'react-router-dom'
import { GetObjectById } from '../../../utils/GetObjectById'
import { api } from '../../../api/datasource';
import './DocumentoHeader.css'

import Entidade from '../../cadastro/Entidade';
import { DocumentoHeaderConfig } from './Documento/Header/DocumentoHeaderConfig';


const APIURLEntidade = "/cadastro/entidade"
const APIURLTipo = "/financeiro/documento_tipo"

export const DocumentoHeader = props => {
    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState({})
    const [popupConfigVisible, setPopupConfigVisible] = useState(false)
    const [entidadeData, setEntidadeData] = useState([])
    const [tipoData, setTipoData] = useState([])
    const [entidadeValue, setEntidadeValue] = useState()
    const [tipoValue, setTipoValue] = useState()
    const [observacaoValue, setObservacaoValue] = useState()
    const [emissaoValue, setEmissaoValue] = useState()
    const [documentoId, setDocumentoId] = useState("")
    let { documentoId: documentoUUID } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const asyncEffect = async () => {
            const resEntidade = await api.get(APIURLEntidade)
            setEntidadeData(resEntidade.data)
            const resTipo = await api.get(APIURLTipo)
            setTipoData(resTipo.data)

            

            if (documentoUUID) {
                const data = await Load(documentoUUID, navigate)

                props.setOcultarProdutosServicos(data.ocultar_produtos_servicos)

                setData(data)
                setDocumentoId(data.id)

                //const entidadeObj = GetObjectById('id', resEntidade.data, data.cad_entidade_id)
                // console.log(entidadeObj)

                //const dataObs = data.observacao ?? ''

                //const docTitle = `${entidadeObj.nome} ${dataObs}`
                //document.title = docTitle
                // console.log(docTitle)

                setEntidadeValue(GetObjectById('id', resEntidade.data, data.cad_entidade_id))
                setTipoValue(GetObjectById('id', resTipo.data, data.cad_fin_documento_tipo_id))
                setEmissaoValue(data.emissao)
                setObservacaoValue(data.observacao)

                setLoaded(true) // NÃO ESTA FUNCIONANDO
            }


            if (!documentoUUID) {
                console.log('novo')
                
                let url = new URL(location.href)
                console.log(url)

                let tipoId = url.searchParams.get('tipo')
                console.log(tipoId)

                if (tipoId > 0) onChangeTipo({ id: tipoId })


            }
        }
        asyncEffect()
    }, [documentoUUID])


    const onChangeEntidade = (value) => {        
        Save('cad_entidade_id', value.id, documentoUUID, navigate, loaded)
    }
    const onChangeTipo = (value) => Save('cad_fin_documento_tipo_id', value.id, documentoUUID, navigate, loaded)
    const onChangeEmissao = (value) => {
        setEmissaoValue(value)
        Save('emissao', value, documentoUUID, navigate, loaded)
    }

    const onChangeObservacao = (value) => {
        setObservacaoValue(value)
        Save('observacao', value, documentoUUID, navigate, loaded)
    }


    return <div className="d-flex flex-column dx-theme-background-color rounded-3 p-3">

        <div className="d-flex justify-content-between pb-2">
            <span className={"align-self-center fw-bold"}>
                Documento: {documentoId || "NOVO"}
                {documentoUUID ? <i type="button" className="ms-2 fa-solid fa-trash-can" onClick={async () => Delete(documentoUUID, navigate)} /> : <></>}
            </span>

            {documentoUUID ? <div className=''>
                <span type="button" className="me-2"
                    onClick={async () => {
                        await AddGroup(documentoUUID, emissaoValue, props.grupos)
                        props.refreshDocumentoGrupo()
                    }}
                >Adicionar grupo</span>
                <i type="button" className="ms-2 fas fa-cogs" onClick={async () => setPopupConfigVisible(true)} />
            </div> : <></>}
        </div>

        <div className="d-sm-flex">
            <SelectBox id="entidade" className={"me-2 flex-fill"}
                label={"Entidade"}
                textField="razao_social_nome"
                data={entidadeData}
                value={entidadeValue}
                onChange={onChangeEntidade}
                required={true}
                dataGridComponent={Entidade}
            />

            <SelectBox id="tipo" className={"me-2 flex-fill"}
                label={"Tipo"}
                textField="nome"
                data={tipoData}
                value={tipoValue}
                onChange={onChangeTipo}
                required={true}
                columnItems={[{ caption: "Nome", dataField: "nome", dataType: "string", sortOrder: "id" }]}
            />

            <DateBox id="emissao" className={"me-2"}
                label={'Emissão'}
                onChange={onChangeEmissao}
                value={emissaoValue}
                required={true}
            />

            <TextBox id="observacao" className={"flex-fill me-2"}
                label={'Observação'}
                onChange={onChangeObservacao}
                value={observacaoValue}
            />
        </div>

        <DocumentoHeaderConfig
            ocultarProdutosServicos={props.ocultarProdutosServicos}
            setOcultarProdutosServicos={props.setOcultarProdutosServicos}
            visible={popupConfigVisible}
            setVisible={setPopupConfigVisible}
            data={data}
        />
    </div>
}