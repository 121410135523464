import React, { useRef, useState, useEffect } from 'react'
import DataGrid, { Toolbar, Item, Selection, Paging, Scrolling, LoadPanel, Editing, Column, GroupPanel, Sorting, SearchPanel, Lookup, KeyboardNavigation, Format, } from 'devextreme-react/data-grid';
import { ConfigButton, DeleteButton, CloneButton, onRowPrepared, AddButton, onInitNewRow } from './utilsUI'
import { dataSource, updateSummary } from './utilsAPI'
import { dataGridColumns } from './dataGridColumns';
import { Button } from 'devextreme-react/button';
// import { LancamentoParams } from './Params/Params'
import { getParamsData } from './Params/utilsAPI';

export const Movimentacoes = props => {
    const gridRef = useRef()
    const [summary, setSummary] = useState({})
    // const [popupParamsVisible, setPopupParamsVisible] = useState(false)

    const [newRowPosition, setNewRowPosition] = useState("last")

    useEffect(() => {
        const load = async () => {
            const paramsData = await getParamsData()
            setNewRowPosition(paramsData.default_new_row_position)

            updateSummary(props.grupoId, setSummary)
        }
        load()
    }, [])

  

    return <div className={'row mx-0 ' + props.className}>
        <DataGrid
            ref={gridRef}
            id={"gridMovimentacao" + props.grupoId}
            keyExpr="id"
            // height={'100%'}
            // height={props.height || "25vh"}
            dataSource={dataSource(props.grupoId, setSummary)}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            errorRowEnabled={false}
            rowAlternationEnabled={false}
            allowColumnReordering={false}
            allowColumnResizing={true}
            onEditorPreparing={(e) => e.editorOptions.dropDownOptions = { width: 400 }}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared}
            onInitNewRow={(row) => onInitNewRow(row, props.grupoId)}
            autoNavigateToFocusedRow={true}
            activeStateEnabled={true}
            onSaved={() => updateSummary(props.grupoId, setSummary)}
            repaintChangesOnly={true}
            // onRowPrepared={onRowPrepared}
        >
            <Toolbar>
                <Item location="before" text={"Movimentações"} ></Item>


                {/* <Item location="after" name="addRowButton" options={{ stylingMode: "contained" }} /> */}

                <Item location="after">
                    <AddButton gridRef={gridRef} dataSource={dataSource} grupoId={props.grupoId} updateSummary={updateSummary} setSummary={setSummary} grupoEmissao={props.grupoEmissao} />
                </Item>


                <Item location="after" name="saveButton" options={{ stylingMode: "contained" }} />
                <Item location="after"><div className="vr mx-2"></div></Item>
                <Item location="after">
                    <CloneButton gridRef={gridRef} dataSource={dataSource} grupoId={props.grupoId} updateSummary={updateSummary} setSummary={setSummary} />
                </Item>
                <Item location="after">
                    <DeleteButton gridRef={gridRef} dataSource={dataSource} grupoId={props.grupoId} updateSummary={updateSummary} setSummary={setSummary} />
                </Item>
                <Item location="after" name="revertButton" options={{ stylingMode: "contained" }} />

                {/* <Item location="after">
                    <ConfigButton setPopupParamsVisible={setPopupParamsVisible} />
                </Item> */}

                {/* setPopupParamsVisible */}
            </Toolbar>



            <Selection mode="multiple" showCheckBoxesMode={"always"} />

            <Editing
                mode="batch" //cell
                allowUpdating={true}
                allowAdding={true}
                allowDeleting={false}
                refreshMode={"repaint"}
                selectTextOnEditStart={false}
                startEditAction={'click'}
                useIcons={true}
                newRowPosition={newRowPosition}
            />

            {/* <KeyboardNavigation editOnKeyPress={true} enterKeyAction={"moveFocus"} enterKeyDirection={"row"} /> */}
            <KeyboardNavigation editOnKeyPress={true} />
            <Paging enabled={false} />
            <Scrolling mode="standard" useNative={true} />
            <LoadPanel enabled={true} />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Sorting mode={'none'} />

            {/* Colunas */}
            {dataGridColumns(props.dataCadastro).map((item, index) => <Column
                key={index} caption={item.caption} dataField={item.dataField} dataType={item.dataType} width={item.width} minWidth={item.minWidth} allowEditing={item.allowEditing} alignment={item.alignment} defaultSortOrder={item.defaultSortOrder}
                fixed={item.fixed}
                fixedPosition={item.fixedPosition}

                sortOrder={item.sortOrder}
                calculateCellValue={item.calculateCellValue}

                calculateDisplayValue={item.calculateDisplayValue}
            >
                {item.lookup && <Lookup
                    dataSource={{ store: { data: item.dataSource, type: 'array', key: 'id' }, paginate: true, pageSize: 5 }}
                    valueExpr={item.valueExpr}
                    displayExpr={item.displayExpr}
                    allowClearing={item.allowClearing}
                />}
                {item.formatType && <Format type={item.formatType} precision={item.formatPrecision} />}
            </Column>
            )}

            <Column type="selection" width={55} fixed={true} fixedPosition={"right"} />
        </DataGrid>

        {/* <LancamentoParams
            visible={popupParamsVisible}
            setVisible={setPopupParamsVisible}
            dataCadastro={props.dataCadastro}

            newRowPosition={newRowPosition}
            setNewRowPosition={setNewRowPosition}
        /> */}

        <div className="d-flex mx-0 p-0 fw-bold rounded-bottom pt-2 pb-2">
            {/* <div className="px-2 flex-grow-1">Items: {summary.total_item ?? 0}</div> */}
            <div className="px-2">Items: {summary.total_item ?? 0}</div>
            <div className="px-2 text-danger">Débitos: {summary.total_debito ?? '0,00'}</div>
            <div className="px-2 text-success">Créditos: {summary.total_credito ?? '0,00'}</div>
            <div className="px-2 text-primary">Saldo: {summary.saldo ?? '0,00'}</div>
        </div>






    </div >

}