import React from 'react'
import { showMessage } from '../../../../components/template/Messages/Messages'
import { Button } from 'devextreme-react/button'
import { formatNumber } from "devextreme/localization"

export const onRowPrepared = async (e) => {
    if (e.rowType == 'data') {
        e.rowElement.style.fontWeight = 'bold'

        const data = e.data
        let isIncomplete = false

        if (!data.cad_produto_servico_classificacao_id) isIncomplete = true
        if (!data.cad_produto_servico_id) isIncomplete = true
        if (!data.cad_centro_custo_id) isIncomplete = true
        if (!data.cad_unidade_medida_id) isIncomplete = true

        if (data.quantidade <= 0) isIncomplete = true
        if (data.valor_unitario <= 0) isIncomplete = true

        if (isIncomplete) e.rowElement.style.backgroundColor = '#f4989c'

    }
}

// Buttons
export const DeleteButton = props => {
    return <Button icon="trash" stylingMode="contained"
        onClick={async () => {
            const dataGridInstance = props.gridRef.current.instance
            const SelectedRowKeys = dataGridInstance.getSelectedRowKeys()
            for (const key of SelectedRowKeys) await props.dataSource.remove(key)
            await dataGridInstance.refresh()
        }} />
}

export const CloneButton = props => {
    return <Button icon="fa-solid fa-clone" stylingMode="contained"
        onClick={async () => {
            const dataGridInstance = props.gridRef.current.instance

            const SelectedRowsData = dataGridInstance.getSelectedRowsData()
            if (SelectedRowsData.length > 1) return showMessage('error', 'Selecione apenas um registro.')
            if (SelectedRowsData.length < 1) return showMessage('error', 'Selecione um registro.')

            let cloneReg = SelectedRowsData[0]
            delete cloneReg.id
            delete cloneReg.v_valor_total
            await props.dataSource.insert(cloneReg)
            await dataGridInstance.refresh()
        }} />
}

export const CadastroButton = props => {
    return <Button icon="fa-solid fa-magnifying-glass" stylingMode="contained" onClick={async () => props.setPopupVisible(true)} />
}

export const updateSummary = async (gridRef, grupoId) => {
    const data = gridRef.current.instance.getVisibleRows()
    let total = 0
    for (const row of data) {
        const valorUnitario = row.data.valor_unitario ?? 0
        const quantidade = row.data.quantidade ?? 0
        const valorDesconto = row.data.valor_desconto ?? 0
        const valorAcrescimo = row.data.valor_acrescimo ?? 0

        const vTotal = ((valorUnitario * quantidade) - valorDesconto + valorAcrescimo).toFixed(2)
        total = total + parseFloat(vTotal)
    }

    total = parseFloat(total).toFixed(2)

    const format = { type: 'fixedPoint', precision: 2 }

    document.getElementById("produto-servico-total-item-label" + grupoId).innerHTML = data.length
    document.getElementById("produto-servico-total-valor-label" + grupoId).innerHTML = formatNumber(total, format)

    document.getElementById("produto-servico-total-item-value" + grupoId).value = data.length
    document.getElementById("produto-servico-total-valor-value" + grupoId).value = total
}