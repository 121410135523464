import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Route, Routes, useNavigate, BrowserRouter } from 'react-router-dom'
import { AuthProvider, useAuth } from './contexts/auth'
import RoutesAuth from './config/main/RoutesAuth'
import { LoginForm } from './pages/usuario/login/login-form'
import Messages from './components/template/Messages/Messages'
import LoadPanel from 'devextreme-react/load-panel'
import './config/css/devextreme-custom.css'
import devices from "devextreme/core/devices"
import Teste from "./teste"
console.log(devices._currentDevice.deviceType)

// Devextreme em português.
import { locale, loadMessages } from "devextreme/localization"
import ptMessages from "devextreme/localization/messages/pt.json"
loadMessages(ptMessages)
locale('pt')

// Devextreme Themes.
const theme = localStorage.getItem('theme') ?? "dark"

const Redirect = ({ to }) => {
    let navigate = useNavigate()
    useEffect(() => navigate(to))
    return null
}

const App = () => {    



    if (theme == 'light') {
        import('./config/themes/light/light.css')
        // import('./config/themes/dark/light-custom.css')
    }

    if (theme == 'dark') {
        import('./config/themes/dark/dark.css')
        import('./config/themes/dark/dark-custom.css')
    }    

    // return <Teste/>
    const { user, loading } = useAuth()

    if (loading) return <LoadPanel visible={true} />
    if (user) return <RoutesAuth />



    return (
        <Routes>
            <Route path='/login' element={<LoginForm />} />
            <Route path="*" element={<Redirect to="/login" />} />
        </Routes>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <AuthProvider>
            <App />
            <Messages />
        </AuthProvider>
    </BrowserRouter>
)