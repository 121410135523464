import React, { useRef, useEffect, useState } from 'react'
import { DefaultContainer } from '../template/Main'
import DXDataGrid, { HeaderFilter, Summary, Format, Lookup, KeyboardNavigation, Selection, Editing, Column, TotalItem, Scrolling, SearchPanel, Toolbar, Item } from 'devextreme-react/data-grid';
import { dataSource } from './utils';

export default props => {
    const gridRef = useRef()
    // const [loading, setLoading] = useState(false)


    const columnItems = props.columnItems || []
    const summaryItems = props.summaryItems || []

    let columnsDefault = [
        { caption: "Inclusão", dataField: "createdAt", dataType: "date", width: "100", allowEditing: false, alignment: "center", sortOrder: props.sortInclusao },
        { caption: "Edição", dataField: "updatedAt", dataType: "date", width: "100", allowEditing: false, alignment: "center" },
    ]

    if (props.hideColumnsDefault) columnsDefault = []


   

    const refreshData = async () => {


        try {
            // setLoading(true)
            let dataGridInstance
            if (props.forwardRef) dataGridInstance = props.forwardRef.current.instance
            if (!props.forwardRef) dataGridInstance = gridRef.current.instance
            if (props.onClickRefresh) await props.onClickRefresh()
            await dataGridInstance.refresh()
        } catch (error) {
            console.error(error)
            // setLoading(false)
        }



    }

    let allowUpdating = props.allowUpdating ?? true
    let allowDeleting = props.allowDeleting ?? true
    let allowAdding = props.allowAdding ?? true

    if (props.editing == true) {
        allowUpdating = false
        allowDeleting = false
        allowAdding = false
    }

    if (props.onRowDblClick) {
        allowUpdating = false
        allowDeleting = false
    }

    return <DefaultContainer title={props.title} hidePageContainer={props.hidePageContainer} >

        {props.beforeComponent && <props.beforeComponent />}


        {/* <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            // position={position}
            // onHiding={hideLoadPanel}
            visible={true}
            // showIndicator={true}
            // shading={shading}
            // showPane={showPane}
            // hideOnOutsideClick={hideOnOutsideClick}
        /> */}

        <DXDataGrid
            id={props.id}
            ref={props.forwardRef || gridRef}
            // dataSource={dataSource(props.apiURL)}
            dataSource={dataSource(props.apiURL, props.apiURLPUT || props.apiURL)}

            // dataSource={gridDataSource}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            errorRowEnabled={false}


            columnResizingMode={"widget"}

            onEditorPreparing={(e) => e.editorOptions.dropDownOptions = { width: 400 }}
            height={props.height || '100%'}
            onRowDblClick={props.onRowDblClick}
            onRowPrepared={props.onRowPrepared}
        >
            {/* <LoadPanel enabled={loading} /> */}


            <Editing
                mode={"batch"}
                allowUpdating={allowUpdating}
                allowDeleting={allowDeleting}
                allowAdding={allowAdding}
                selectTextOnEditStart={false}
                startEditAction={'click'}
                useIcons={true}
            />

            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction={"startEdit"}
                enterKeyDirection={"column"}
            />

            {/* <Paging defaultPageSize={200} />             */}
            <Scrolling mode="infinite" />

            <HeaderFilter visible={true} allowSearch={true} width={"400px"} height={"500px"} />
            <Selection mode={props.selectionMode || "single"} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />


            <Toolbar>
                <Item location="before" text={props.title} />

                <Item location="before">
                    {props.showRefreshButton && <i type="button" className="mx-2 fas fa-sync-alt"

                        onDoubleClick={() => { location.reload() }}
                        onClick={async () => {
                            await refreshData()
                        }} />
                    }

                </Item>


                <Item location="before">{props.ComponentToolBar && <props.ComponentToolBar />}</Item>

                <Item name="addRowButton" />
                <Item name="saveButton" />
                <Item name="revertButton" />
                <Item name="searchPanel" id="searchPanel" />

            </Toolbar>


            {columnItems.map((item, index) => {
                return <Column
                    key={index}
                    fixed={item.fixed}
                    fixedPosition={item.fixedPosition}
                    caption={item.caption}
                    dataField={item.dataField}
                    dataType={item.dataType || ""}
                    width={item.width}
                    sortOrder={item.sortOrder}
                    minWidth={item.minWidth}
                    allowEditing={item.allowEditing ?? true}
                    alignment={item.alignment || "left"}
                    groupIndex={item.groupIndex}
                    allowFiltering={item.allowFiltering ?? true}
                    cellRender={item.cellRender}


                >
                    {/* {item.allowHeaderFiltering && <HeaderFilter visible={true} allowSearch={true} />} */}
                    {item.lookup && <Lookup dataSource={item.dataSource} valueExpr={item.valueExpr} displayExpr={item.displayExpr} />}
                    {item.formatType && <Format type={item.formatType} precision={item.formatPrecision} />}
                </Column>
            })}

            {columnsDefault.map((item, index) => {
                return <Column
                    key={index}
                    caption={item.caption || "null"}
                    dataField={item.dataField}
                    dataType={item.dataType || "string"}
                    width={"100"}
                    allowEditing={false}
                    sortOrder={item.sortOrder}
                    alignment={"center"}
                />
            })}

            <Summary>
                {summaryItems.map((item, index) => {
                    return <TotalItem
                        key={index}
                        column={item.column}
                        summaryType={item.summaryType}
                        displayFormat={"{0}"}
                        valueFormat={{ type: item.type, precision: item.precision }}
                    />
                })}

            </Summary>
        </DXDataGrid>
        {props.afterComponent && <props.afterComponent />}
    </DefaultContainer>
}