import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'
import { RoutesAPP } from '../../config/main/RoutesAPP'
import { SwitchTheme, setTheme } from './SwitchTheme'
import './Header.css'



export default () => {
    const { user, signOut } = useAuth()



    return <nav id="header" className="navbar navbar-expand-sm fixed-top dx-theme-background-color theme-header-border-color">
        <div className="container-fluid py-0">
            <a className="navbar-brand my-0 py-0" href="/home">
                {/* <h2 id="header-title" className="p-0 m-0 fw-bold">DevExtreme</h2> */}
                <h2 id="header-title" className="p-0 m-0 fw-bold theme-header-title">MyMoney</h2>
                <h5 id="header-brand" className="p-0 m-0 theme-header-brand">by Sibe</h5>
            </a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav me-auto my-2 my-sm-0 navbar-nav-scroll dx-theme-accent-as-text-color">



                    {/* Menu Cadastros */}
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle dx-theme-accent-as-text-color" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Cadastros</a>
                        <ul className="dropdown-menu dx-theme-accent-as-text-color" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to={RoutesAPP.Cadastro.entidade}>{'Entidade'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Cadastro.centro_custo}>{'Centro de Custo'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Cadastro.produto_servico}>{'Produto/Serviço'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Cadastro.produto_servico_classificacao}>{'Produto/Serviço - Classificação'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Cadastro.financeiro_conta}>{'Conta'}</Link>
                        </ul>
                    </li>

                    {/* Menu Financeiro */}
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle dx-theme-accent-as-text-color" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Financeiro</a>
                        <ul className="dropdown-menu dx-theme-accent-as-text-color" aria-labelledby="navbarDropdown">
                            
                            <Link className="dropdown-item" to={'/financeiro/documentos/create?tipo=1'}>{'Pagar'}</Link>
                            <Link className="dropdown-item" to={'/financeiro/documentos/create?tipo=2'}>{'Receber'}</Link>
                            
                            <Link className="dropdown-item" to={RoutesAPP.Financeiro.Documentos.index}>{'Documentos'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Financeiro.Saldos}>{'Saldos'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Financeiro.lancamento_produto_servico}>{'Lançamentos de produtos e serviços'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Financeiro.extrato}>{'Extrato'}</Link>

                            <Link className="dropdown-item" to={RoutesAPP.Financeiro.Documentos.create_movimento}>{'Pré-Documento'}</Link>

                            <Link className="dropdown-item" to={RoutesAPP.Financeiro.parametros}>{'Parâmetros'}</Link>


                        </ul>
                    </li>

                    {/* Menu Relatório */}
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle dx-theme-accent-as-text-color" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Relatório</a>
                        <ul className="dropdown-menu dx-theme-accent-as-text-color" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to={RoutesAPP.Relatorio.custo_tabela}>{'Custos (Tabela)'}</Link>
                            <Link className="dropdown-item" to={RoutesAPP.Relatorio.custo_pivot}>{'Custos (Pivot)'}</Link>
                        </ul>
                    </li>

                    <li className="nav-item">                        
                            <Link className="nav-link dropdown-menu dx-theme-accent-as-text-color" to={"/pendencias"}>{'Pendências do Sistema'}</Link>
                            {/* <Link className="dropdown-item" to={RoutesAPP.Relatorio.custo_pivot}>{'Custos (Pivot)'}</Link> */}
                    </li>



                </ul>


                <ul className="navbar-nav ms-auto">
                    <li className="nav-item dropdown me-auto">
                        <a className="nav-link dropdown-toggle dx-theme-accent-as-text-color" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{user.full_name}</a>
                        <ul className="dropdown-menu dx-theme-accent-as-text-color dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li className="nav-item dropdown">
                                <a onClick={() => { signOut() }} className="dropdown-item" href="#"><i className="me-2 fas fa-sign-out-alt" />Sair</a>
                                <a onClick={() => { setTheme() }} className="dropdown-item" href="#"><SwitchTheme /></a>
                            </li>
                        </ul>
                    </li>
                </ul>


            </div>
        </div>
    </nav>
}