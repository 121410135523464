import React, { useEffect, useState } from 'react'
import './financeiro-documento.css'

import { DefaultContainer } from '../../../components/template/Main'
import { DocumentoHeader } from './DocumentoHeader'
import { Grupo } from './Grupo'
import { useParams } from 'react-router-dom'
import { LoadGroups } from './utils/Documento';
import { LoadCadastro } from './utils/LoadCadastro'

export const Documento = props => {
    //document.title = 'MyMoney - Documento'

    let { documentoId } = useParams()
    const [grupos, setGrupos] = useState([])
    const [ocultarProdutosServicos, setOcultarProdutosServicos] = useState(true)
    const [dataCadastro, setDataCadastro] = useState([])

    const loadDataCadastro = async () => {
        const dataFinanceiroConta = await LoadCadastro('/cadastro/financeiro_conta')
        const dataFinanceiroMovimentacaoTipo = await LoadCadastro('/cadastro/financeiro_movimentacao_tipo')
        const dataFinanceiroProdutoServico = await LoadCadastro('/cadastro/produto_servico')
        const dataFinanceiroProdutoServicoClassificacao = await LoadCadastro('/cadastro/produto_servico_classificacao')
        const dataFinanceiroCentroCusto = await LoadCadastro('/cadastro/centro_de_custo')
        const dataUnidadeMedida = await LoadCadastro('/cadastro/unidade_de_medida')

        setDataCadastro({
            financeiro_conta: dataFinanceiroConta,
            financeiro_movimentacao_tipo: dataFinanceiroMovimentacaoTipo,
            produto_servico: dataFinanceiroProdutoServico,
            produto_servico_classificacao: dataFinanceiroProdutoServicoClassificacao,
            centro_de_custo: dataFinanceiroCentroCusto,
            unidade_de_medida: dataUnidadeMedida,
        })
        // console.log('loadDataCadastro')
    }

    const refreshDocumentoGrupo = async () => {        
        const groups = await LoadGroups(documentoId)        
        setGrupos(groups)
        // console.log('refreshDocumentoGrupo')
    }

    useEffect(() => {
        const asyncEffect = async () => {

            if (documentoId) {
                await refreshDocumentoGrupo()
                await loadDataCadastro()
                // console.log('asyncEffect Documento')
            }
        }
        asyncEffect()
    }, [documentoId])

    return <DefaultContainer title={"Documento"}>


        <DocumentoHeader
            grupos={grupos}
            refreshDocumentoGrupo={refreshDocumentoGrupo}
            ocultarProdutosServicos={ocultarProdutosServicos}
            setOcultarProdutosServicos={setOcultarProdutosServicos}
        />

        {grupos.map((grupo, index) => <Grupo
            key={grupo.id}
            groupsLength={grupos.length}
            index={index}
            documentoId={documentoId}
            grupoId={grupo.id}
            refreshDocumentoGrupo={refreshDocumentoGrupo}
            dataCadastro={dataCadastro}
            loadDataCadastro={loadDataCadastro}
            ocultarProdutosServicos={ocultarProdutosServicos} 
        />)}
    </DefaultContainer>
}