
const baseUrlFinanceiroDocumento = '/financeiro/documento'
export class RoutesAPI {

    static financeiro = {
        documento: {
            base: baseUrlFinanceiroDocumento,
            _tipo: baseUrlFinanceiroDocumento + "_tipo",
            _grupo: {
                base: baseUrlFinanceiroDocumento + "_grupo",
                _produto_servico: baseUrlFinanceiroDocumento + "_grupo" + '_produto_servico',
            },
        }
    }


}