import { api } from "../../../../../api/datasource"

export const Save = async (field, value, setValue) => {
    console.log("Save: " + field + " = " + value)
    setValue(value)
    await api.put("/financeiro", "documento_grupo_movimentacao_params", { [field]: value })
}


export const getParamsData = async () => {
    try {
        const res = await api.get("/financeiro/documento_grupo_movimentacao_params")
        return res.data
    } catch (error) {
        console.error(error)
        return []
    }
}


export const Load2 = async () => {
    const paramsData = await getParamsData()
    console.log(paramsData)

    // setCompensado(parseInt(paramsData.default_compensado))
    // setNewRowPosition(paramsData.default_new_row_position)
    // setConta(parseInt(paramsData.default_conta_id))
    // setTipo(parseInt(paramsData.default_tipo_id))
}