import { showMessage } from '../components/template/Messages/Messages'

const server =  process.env.REACT_APP_API_URL

let accessToken = '1234'
for (const key in localStorage) {
    if (key.includes("accessToken")) accessToken = localStorage.getItem(key)
}

const headers = new Headers({
    'Content-Type': 'application/json',
    'authorization': accessToken,
})

export const api = {

    get: async (URL) => {        
        try {            
            const response = await fetch(server + URL, {
                headers: headers,
            })
            const data = await response.json()

            if (response.status != 200) throw new Error(data.error)
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            showMessage("ERROR", e.message + ". URL: " + URL)
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                message: e.message
            }
        }
    },

    put: async (URL, _id, values) => {
        try {
            const response = await fetch(server + URL + `/${_id}`, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(values)
            });
            const data = await response.json();
            if (response.status != 200) throw new Error(data.error);
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            showMessage("ERROR", e.message)
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                message: e.message
            };
        }
    },
    postReturnData: async (URL, values) => {        
        try {
            const response = await fetch(server + URL, {
                method: 'POST',
                headers: headers,                
                body: JSON.stringify(values)
            })
            const data = await response.json();

            if (response.status == 401) {
                console.log(data.error)
                return {
                    isOk: false,
                    // data: [],
                    // message: data.message,
                };
            }

            if (response.status != 200) throw new Error(data.error);
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            console.log(e)


            showMessage("ERROR", e.message)
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                message: e.message,
            };
        }
    },
    post: async (URL, values) => {        
        try {
            const response = await fetch(server + URL, {
                method: 'POST',
                headers: headers,                
                body: JSON.stringify(values)
            })
            const data = await response.json();

            if (response.status == 401) {
                console.log(data.error)
                return {
                    isOk: false,
                    // data: [],
                    // message: data.message,
                };
            }

            if (response.status != 200) throw new Error(data.error);
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            console.log(e)


            showMessage("ERROR", e.message)
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                // data: values,
                message: e.message,
            };
        }
    },
    delete: async (URL) => {
        try {            
            const response = await fetch(server + URL, {
                method: 'DELETE',
                headers: headers,
            });
            const data = await response.json();
            if (response.status != 200) throw new Error(data.error);
            return {
                isOk: true,
                // data: data
            }
        } catch (e) {
            showMessage("ERROR", e.message)
            console.error(e.message)
            return {
                isOk: false,
                // data: [],
                message: e.message
            };
        }
    },

    // deleteByKey: async (URL, key) => {
    //     try {
    //         const response = await fetch(apiURL() + URL + '/' + key, {
    //             method: 'DELETE',
    //             headers: headers,
    //         });
    //         const data = await response.json();
    //         if (response.status != 200) throw new Error(data.error);
    //         return {
    //             isOk: true,
    //             data: data
    //         }
    //     } catch (e) {
    //         showMessage("ERROR", e.message)
    //         console.error(e.message)
    //         return {
    //             isOk: false,
    //             data: [],
    //             message: e.message
    //         };
    //     }
    // }
}