const baseUrlCadastro = '/cadastro'
const baseUrlFinanceiro = '/financeiro'
const baseUrlRelatorio = '/relatorio'
const baseUrlLancamentoFinanceiro = '/financeiro'

export class RoutesAPP {

    // Cadastros
    static Cadastro = {
        entidade: baseUrlCadastro + '/entidade',
        centro_custo: baseUrlCadastro + '/centro_custo',
        produto_servico: baseUrlCadastro + '/produto_servico',
        produto_servico_classificacao: baseUrlCadastro + '/produto_servico_classificacao',
        financeiro_conta: baseUrlCadastro + '/financeiro_conta',
    }

    static Financeiro = {
        index: baseUrlLancamentoFinanceiro,
        Documentos: {
            index: baseUrlLancamentoFinanceiro + '/documentos',
            create_movimento: baseUrlFinanceiro + '/documentos' + '/create_movimento',
        },
        Saldos: baseUrlFinanceiro + '/saldos',
        lancamento_produto_servico: baseUrlFinanceiro + '/lancamento/produto_servico',
        extrato: baseUrlFinanceiro + '/extrato',

        parametros: baseUrlFinanceiro + '/parametros',

    }

    static Relatorio = {
        custo_tabela: baseUrlRelatorio + "/custo_tabela",
        custo_pivot: baseUrlRelatorio + "/custo_pivot"
    }

    static Pendencias = '/pendencias'

    // Login
    static Login = '/login'
    static ResetPassword = '/resetpassword'
}