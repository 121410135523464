import { api } from '../../../../api/datasource'
import { dxConfirm } from '../../../../components/template/Messages/Messages'
import { RoutesAPI } from '../../../../config/main/RoutesAPI'

const DocumentoGrupoURL = RoutesAPI.financeiro.documento._grupo.base

export const Save = async (key, value, grupoId) => {
    const uuid = 1234
    let documento = {}
    documento[key] = value
    await api.put(DocumentoGrupoURL + "/" + uuid, grupoId, documento)
}

export const Delete = async (documentoUUID, grupoId, refreshDocumentoGrupo) => {
    if (documentoUUID) {
        const confirm = await dxConfirm('Deseja deletar o grupo selecionado?')

        if (confirm) {
            const resAPI = await api.delete(DocumentoGrupoURL + "/" + documentoUUID + "/" + grupoId)
            refreshDocumentoGrupo()
            return resAPI.data
        }
    }
}

export const Load = async (grupoId) => {
    const documentoUUID = 1234
    const resAPI = await api.get(DocumentoGrupoURL + "/" + documentoUUID + "/" + grupoId)

    if (resAPI.isOk) {                
        return resAPI.data[0]
    } else {
        console.log('Grupo não localizado!')
    }
}

export const Clone = async (documentoUUID, grupoId, refreshDocumentoGrupo) => {
    const confirm = await dxConfirm('Deseja clonar o grupo selecionado?')
    if (confirm) {
        await api.post('/financeiro/documento_grupo_clone/' + documentoUUID + "/" + grupoId)
        refreshDocumentoGrupo()
    }
}