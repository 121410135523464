import React, { useEffect, useState } from 'react'
import DateBoxDevExtreme from 'devextreme-react/date-box';
// import './index.css'

export const DateBox = props => {
    // const [validationStatus, setValidationStatus] = useState("invalid")
    return <div id={props.id} className={props.className}>
        <div className="d-flex">
            <div className="p-0 flex-grow-1">
                <DateBoxDevExtreme
                    label={props.label}
                    labelMode={"static"}
                    stylingMode={props.stylingMode ?? "underlined"}
                    type="date"
                    displayFormat={props.displayFormat || "dd/MM/yyyy"}
                    useMaskBehavior={true}
                    validationStatus={!props.value && props.required == true ? "invalid" : "valid"}
                    onValueChanged={(e) => {
                        if (e.event) {
                            props.onChange(e.value)
                        }
                    }}

                    value={props.value}
                />
            </div>
        </div>
    </div>
}