import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../../contexts/auth';
import { useNavigate } from "react-router-dom";
import { showMessage } from '../../../components/template/Messages/Messages';





import './login-form.css';

export const LoginForm = () => {

  const navigate = useNavigate()
  // const history = useHistory();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const email = formData.current.email
    const senha = CryptoJS.AES.encrypt(formData.current.password, process.env.REACT_APP_AUTH_SECRECT).toString();
    setLoading(true)

    const login = await signIn(email, senha)


    if (!login.auth) {
      setLoading(false)
      showMessage('error', login.message)
      notify(login.message, 'error', 5000)
    }

    if (login.auth) {
      navigate('/home')
      location.reload()
    }

  }, [signIn])

  // return <div>
  //   <button onClick={async () => {

      

  //     const teste = await dxConfirm('Deseja clonar o grupo?')

  //     console.log(teste)

  //     // showMessageConfirm('OI')


  //   }}>TESTE</button>
  //   <MessageConfirm />
  // </div>

  return <div className="d-flex flex-coumn min-vh-100 min-vw-100">
    <div className="d-flex flex-grow-1 justify-content-center align-items-center">

      <form id="login_content" className={'login-form p-4 rounded-2'} onSubmit={onSubmit}>
        <span className="h4">Área Restrita</span>
        <Form className="my-2" formData={formData.current} disabled={loading}>
          <Item
            dataField={'email'}
            editorType={'dxTextBox'}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="E-mail é obrigatório" />
            <EmailRule message="E-mail é inválido" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={'password'}
            editorType={'dxTextBox'}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Senha é obrigatória" />
            <Label visible={false} />
          </Item>

          <ButtonItem>
            <ButtonOptions
              width={'250'}
              type={'default'}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {
                  loading
                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                    : 'Entrar'
                }
              </span>
            </ButtonOptions>
          </ButtonItem>

          <Item>
            <div className={'link'}>
              <Link to={'/reset-password'}>Esqueceu a senha?</Link>
            </div>
          </Item>

        </Form>
      </form>
    </div>
  </div>
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'E-mail', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Senha', mode: 'password' };
