const CalculateCellValueValorTotal = (rowData) => {
    try {
        const valorUnitario = rowData.valor ?? 0
        const valorDesconto = rowData.valor_desconto ?? 0
        const valorAcrescimo = rowData.valor_acrescimo ?? 0
        return valorUnitario - valorDesconto + valorAcrescimo
    } catch (error) {
        return 0
    }
}

export const dataGridColumns = (dataCadastro) => {
    return [
        { caption: "#", dataField: "numero_item", dataType: "number", alignment: "center", width: 40, allowEditing: false, fixed: true, fixedPosition: "left" },
        { caption: "Parcela", dataField: "parcela", dataType: "number", alignment: "center", width: 80 },

        { caption: "Emissão", dataField: "data_emissao", dataType: "date", alignment: "center", width: 120, },
        { caption: "Vencimento", dataField: "data_vencimento", dataType: "date", alignment: "center", width: 120, },
        { caption: "Compensação", dataField: "data_compensacao", dataType: "date", alignment: "center", width: 120, },

        { caption: "Conta", dataField: "cad_financeiro_conta_id", width: "250", lookup: true, dataSource: dataCadastro.financeiro_conta ?? [], valueExpr: "id", displayExpr: "nome", allowClearing: true },
        { caption: "Tipo", dataField: "tipo", width: "100", lookup: true, dataSource: dataCadastro.financeiro_movimentacao_tipo ?? [], valueExpr: "id", displayExpr: "nome" },

        { caption: "Valor", dataField: "valor", dataType: "number", width: "100", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },
        { caption: "Desc", dataField: "valor_desconto", dataType: "number", width: "65", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },
        { caption: "Acres", dataField: "valor_acrescimo", dataType: "number", width: "65", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },
        // { caption: "Total", dataField: "v_valor_total", dataType: "number", width: "100", alignment: "center", formatType: "fixedPoint", formatPrecision: 2, allowEditing: false },
        { name: "valor_total", caption: "Total", calculateCellValue: CalculateCellValueValorTotal, formatType: "fixedPoint", formatPrecision: 2, width: "100" },
        { caption: "Compensado", dataField: "valor_total_compensado", dataType: "number", width: "100", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },
        { caption: "Observação", dataField: "observacao", dataType: "string", minWidth: "100" },
    ]
}