import React, { useRef, useState, useEffect } from 'react'
import { Popup } from '../../../../../components/Popup/Popup'
import { api } from '../../../../../api/datasource'
import { CheckBox } from 'devextreme-react/check-box'
import { DocumentoHeaderConfigLoad as Load } from './utilsAPI'
import { Save } from '../../utils/Documento'

const Row = props => {
    return <div className="row p-2 align-items-center">
        <div className="col-sm-6">{props.title}</div>
        <div className="col-sm-4">{props.component}</div>
    </div>
}

export const DocumentoHeaderConfig = props => {
    const [manterAberto, setManterAberto] = useState()
    const [recorrente, setRecorrente] = useState()
    const [preDocumento, setPreDocumento] = useState()
    const [anoMesRefByMov, setAnoMesRefByMov] = useState()

    const Content = () => {
        return <div>
            <div className="p-2 fw-bold dx-theme-background-color dx-theme-accent-as-text-color">
                Configuração do Documento
            </div>

            <Row title={"Manter Documento Aberto"} component={<CheckBox defaultValue={manterAberto} onValueChanged={(e) => {
                Save("manter_aberto", e.value, props.data.uuid)
                setManterAberto(e.value)
                props.data.manter_aberto = e.value
            }} />} />

            <Row title={"Ocultar Produtos/Serviços"} component={<CheckBox defaultValue={props.ocultarProdutosServicos} onValueChanged={(e) => {
                Save("ocultar_produtos_servicos", e.value, props.data.uuid)
                props.setOcultarProdutosServicos(e.value)
                props.data.ocultar_produtos_servicos = e.value
            }} />} />

            <Row title={"Recorrente"} component={<CheckBox defaultValue={recorrente} onValueChanged={(e) => {
                Save("recorrente", e.value, props.data.uuid)
                setRecorrente(e.value)
                props.data.recorrente = e.value
            }} />} />

            <Row title={"Pré-Documento"} component={<CheckBox defaultValue={preDocumento} onValueChanged={(e) => {
                Save("pre_documento", e.value, props.data.uuid)
                setPreDocumento(e.value)
                props.data.pre_documento = e.value
            }} />} />

            <Row title={"AnoMêsRef. Por Movimento"} component={<CheckBox defaultValue={anoMesRefByMov} onValueChanged={(e) => {
                Save("anomesref_bymov", e.value, props.data.uuid)
                setAnoMesRefByMov(e.value)
                props.data.anomesref_bymov = e.value
            }} />} />

        </div>
    }

    return <React.Fragment>
        <Popup
            label={"Parâmetros"}
            visible={props.visible}
            width={"30vw"}
            setVisible={props.setVisible}
            content={<Content />}
            onShown={() => Load(props.data, props.setOcultarProdutosServicos, setManterAberto, setRecorrente, setPreDocumento, setAnoMesRefByMov)}
        />
    </React.Fragment>
}