import React from 'react'
import SibeDatagrid from '../../components/DataGrid/DataGrid'

const tipoData = [
    { id: 2, nome: "ESPÉCIE" },
    { id: 1, nome: "CONTA CORRENTE" },
    { id: 3, nome: "CARTÃO DE CRÉDITO" },
    { id: 4, nome: "APLICAÇÃO" },
]

export default () => {

    const title = "Cadastro de Conta"
    const apiURL = "/cadastro/financeiro_conta"
    const columnItems = [
        { caption: "Nome", dataField: "nome", dataType: "string", sortOrder: "asc" },
        { caption: "Tipo", dataField: "tipo", width: "250", lookup: true, dataSource: tipoData, valueExpr: "id", displayExpr: "nome" },
        { caption: "Limite", dataField: "cartao_credito_limite", dataType: "number", width: "110", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },
        { caption: "Vencimento (dia)", dataField: "cartao_credito_vencimento", dataType: "number", width: "110", alignment: "center" },
        { caption: "Fechamento (dia)", dataField: "cartao_credito_fechamento", dataType: "number", width: "110", alignment: "center" },
    ]

    return <SibeDatagrid columnItems={columnItems} apiURL={apiURL} title={title} />
}