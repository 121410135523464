import React from 'react';
import { DefaultContainer } from '../../components/template/Main'

export const Index = () => (

  <DefaultContainer title={"Home"}>
    <h2 className=''>Olá, seja bem-vindo!</h2>
    <div className=''>
      <div className=''>
        <p>Navegue utilizando o menu acima.</p>


        {/* <div className="dx-theme-accent-as-background-color p-2 m-2"> dx-theme-accent-as-background-color </div>
        <div className="dx-theme-background-color p-2 m-2"> dx-theme-background-color </div>
        <div className="dx-theme-border-color-as-background-color  p-2 m-2"> dx-theme-border-color-as-background-color </div>
        <div className="dx-theme-text-color-as-background-color  p-2 m-2"> dx-theme-text-color-as-background-color </div>

        <div className="dx-theme-background-color-as-border-color p-2 m-2"> dx-theme-background-color-as-border-color </div>
        <div className="dx-theme-background-color-as-text-color p-2 m-2"> dx-theme-background-color-as-text-color </div> */}


      </div>
    </div>
  </DefaultContainer>
)