import React, { useState, useEffect, useRef } from 'react'
import 'devextreme-react/text-area'
import { useAuth } from '../../../contexts/auth'
import PivotGrid, { Texts, FieldChooser, FieldPanel, HeaderFilter, StateStoring } from 'devextreme-react/pivot-grid'
import { DefaultContainer } from '../../../components/template/Main';
import Chart, { AdaptiveLayout, CommonSeriesSettings, Size, Tooltip, } from 'devextreme-react/chart';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { api } from '../../../api/datasource';

export const CustoPivot = props => {
    const [dataRows, setDataRows] = useState([])

    const [chartVisible, setChartVisible] = useState("invisible")

    const refChart = useRef(null)
    const refPivot = useRef(null)

    useEffect(() => {
        refPivot.current.instance.bindChart(refChart.current.instance);
        const load = async () => {
            const res = await api.get('/relatorio/custo_pivot')
            setDataRows(res.data)
        }
        load()
    }, []);

    const fields = [
        // Filtros
        { area: "filter", caption: "Sacado", dataField: "sacado_nome" },
        { area: "filter", caption: "Dia", dataField: "documento_emissao", sortOrder: "desc", dataType: "date", format: "dd/MM/yyyy" },
        { area: "filter", caption: "Tipo", dataField: "documento_tipo_nome" },
        { area: "filter", caption: "Recorrente", dataField: "recorrente_nome" },


        // Linhas
        { area: "row", caption: "Centro de Custo", dataField: "item_centro_custo", sortOrder: "asc" },
        { area: "row", caption: "Classificação", dataField: "item_classificacao", sortOrder: "asc" },
        { area: "row", caption: "Produto/Serviço", dataField: "item_produto_servico", sortOrder: "asc" },
        { area: "row", caption: "Emitente", dataField: "emitente_nome", sortOrder: "asc" },

        //     // Colunas
        { area: "column", caption: "Ano", dataField: "documento_grupo_emissao_ano", },
        { area: "column", caption: "Mês", dataField: "documento_grupo_emissao", dataType: "date", groupInterval: "month" },

        //     // Medidas
        { isMeasure: true, area: "data", dataType: "number", caption: "Valor", dataField: "item_valor_total", summaryType: "sum", format: "#,##0.00" },
    ]
    const dataSource = new PivotGridDataSource({ retrieveFields: false, fields: fields, store: dataRows })

    const customizeTooltip = (args) => { return { html: `${args.seriesName}<div class='currency'>${args.originalValue.toLocaleString("pt-BR")}</div>` } }




    return (<DefaultContainer title={'Relatório de Custos (Pivot)'}>





        <i type="button" className="mx-1 fas fa-sync-alt"
            onDoubleClick={async () => SetCustomArrayData(setDataRows)}
            onClick={async () => SetCustomArrayData(setDataRows)}
        />

        <i type="button" className="mx-1 fas fa-chart-bar"

            onClick={async () => {
                if (chartVisible == "invisible") {
                    setChartVisible("")
                } else {
                    setChartVisible("invisible")
                }
            }}
        />




        <PivotGrid
            ref={refPivot}
            id="pivot-gridrelatorio-custo-pivot"
            dataSource={dataSource}
            allowSortingBySummary={true}
            allowSorting={true}
            allowFiltering={true}
            showBorders={true}

            showColumnTotals={true}
            showColumnGrandTotals={true}

            showRowTotals={true}
            showRowGrandTotals={true}
        >
            <StateStoring enabled={true} type="localStorage" storageKey="pivot-gridrelatorio-custo-pivot" />

            <Texts grandTotal={"Total Geral"} />

            <HeaderFilter
                allowSearch={true}
                showRelevantValues={true}
                width={300}
                height={400}
            />
            <FieldPanel
                showColumnFields={true}
                showDataFields={false}
                showFilterFields={true}
                showRowFields={true}
                allowFieldDragging={true}
                visible={true}
            />

            <FieldChooser enabled={false} visible={false} height={"75vh"} width={"50vw"} allowSearch={true} />

        </PivotGrid>

        <Chart className={"mt-4 " + chartVisible} id="chart" ref={refChart}>
            <Size height={320} />
            <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
            <CommonSeriesSettings type="bar" />
            <AdaptiveLayout width={450} />
        </Chart>

    </DefaultContainer >
    )
}