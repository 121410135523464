import React, { useState, useEffect } from 'react'
import { ProdutosServicos } from './ProdutosServicos/ProdutosServicos'
import { Movimentacoes } from './Movimentacoes/Movimentacoes'
import { NumberBox } from '../../../components/Editors/NumberBox'
import { DateBox } from '../../../components/Editors/DateBox'
import { TextBox } from '../../../components/Editors/TextBox'
import { LoadCadastro } from './utils/LoadCadastro'

import('./grid.css')

// import { DeleteGroup } from './utils/Documento'
import { Load, Save, Delete, Clone } from './utils/Grupo';
import './financeiro-documento.css'
import { dxConfirm } from '../../../components/template/Messages/Messages'

export const Grupo = props => {

    const [emissaoValue, setEmissaoValue] = useState()
    const [referenciaAnoValue, setReferenciaAnoValue] = useState()
    const [referenciaMesValue, setReferenciaMesValue] = useState()
    const [observacaoValue, setObservacaoValue] = useState()

    const onChangeEmissao = (value) => {
        setEmissaoValue(value)
        Save('emissao', value, props.grupoId)
    }

    const onChangeReferenciaAno = (value) => {
        setReferenciaAnoValue(value)
        Save('referencia_ano', value, props.grupoId)
    }

    const onChangeReferenciaMes = (value) => {
        setReferenciaMesValue(value)
        Save('referencia_mes', value, props.grupoId)
    }

    const onChangeObservacao = (value) => {
        setObservacaoValue(value)
        Save('observacao', value, props.grupoId)
    }

    useEffect(() => {
        const asyncEffect = async () => {
            const data = await Load(props.grupoId)

            setEmissaoValue(data.emissao)
            setReferenciaAnoValue(data.referencia_ano)
            setReferenciaMesValue(data.referencia_mes)
            setObservacaoValue(data.observacao)

        }
        asyncEffect()
    }, [])



    // console.log(props.groupsLength)
    let multipleGroups = false
    if (props.groupsLength > 1) multipleGroups = true



    return <div className="d-flex flex-column rounded mt-3">


        {
            multipleGroups &&
            <div className="financeiro_documento_grupo_header_panel py-0 my-0 mb-2 d-flex justify-content-center">
                <h3 className="fw-bold px-2 py-2 my-0">Grupo {props.index + 1}</h3>                
            </div>
        }



        <div className="d-sm-flex align-items-center fw-bold mx-0 pb-2">


            {
                !multipleGroups &&
                <div>
                    <span className="fw-bold px-0 mx-2 theme-header-title">Grupo {props.index + 1}</span>
                    |
                </div>
            }


            <i type="button" className="fa-regular fa-clone mx-2"
                onClick={() => Clone(props.documentoId, props.grupoId, props.refreshDocumentoGrupo)}
            />

            <i type="button" className="fa-solid fa-trash-can mx-2"
                onClick={() => Delete(props.documentoId, props.grupoId, props.refreshDocumentoGrupo)}
            />

            <div className='d-flex'>
                <NumberBox className={"ms-2"}
                    label={'Ano'}
                    stylingMode="outlined"
                    onChange={onChangeReferenciaAno}
                    value={referenciaAnoValue}
                    min={2000} max={2100}
                    width={"100px"}
                    required={true}
                />
                <NumberBox className={"ms-2"}
                    label={'Mês'}
                    stylingMode="outlined"
                    onChange={onChangeReferenciaMes}
                    value={referenciaMesValue}
                    min={1} max={12}
                    format={'00'}
                    width={"80px"}
                    required={true}
                />
            </div>

            <DateBox className={"col-sm-2 ms-2"}
                label={'Emissão'}
                stylingMode="outlined"
                onChange={onChangeEmissao}
                value={emissaoValue}
                required={true}
            />

            <TextBox className={"flex-fill ms-2"}
                label={'Observação'}
                stylingMode="outlined"
                onChange={onChangeObservacao}
                value={observacaoValue}
            />
        </div>





        {props.ocultarProdutosServicos ? <></> :
            <ProdutosServicos
                className={"mb-4"}
                grupoId={props.grupoId}
                dataCadastro={props.dataCadastro}
                loadDataCadastro={props.loadDataCadastro}
            />
        }

        <Movimentacoes
            grupoId={props.grupoId}
            dataCadastro={props.dataCadastro}
            grupoEmissao={emissaoValue}
        />





    </div>
}