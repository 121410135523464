
const CalculateCellValueValorTotal = (rowData) => {
    try {
        const valorUnitario = rowData.valor_unitario ?? 0
        const quantidade = rowData.quantidade ?? 0
        const valorDesconto = rowData.valor_desconto ?? 0
        const valorAcrescimo = rowData.valor_acrescimo ?? 0
        const vTotal = ((valorUnitario * quantidade) - valorDesconto + valorAcrescimo).toFixed(2)
        return vTotal
    } catch (error) {
        return 0
    }
}

const CalculateCellValueValorUnitario = (rowData) => {
    return rowData.valor_unitario
    try {
        const valorUnitario = rowData.valor_unitario ?? 0
        const quantidade = rowData.quantidade ?? 0
        const valorTotalVirtual = rowData.valor_total_virtual ?? 0
        const valorDesconto = rowData.valor_desconto ?? 0
        const valorAcrescimo = rowData.valor_acrescimo ?? 0

      

        if (
            valorUnitario === 0 &&
            quantidade > 0 &&
            valorTotalVirtual > 0
        ) {
            console.log("NÃO EXISTE")
            // rowData.valor_unitario = 888            
            return ((valorTotalVirtual / quantidade) - valorDesconto + valorAcrescimo).toFixed(2)
        }

        return rowData.valor_unitario
    } catch (error) {
        console.log(error)
        return 0
    }
}


export const dataGridColumns = (dataCadastro) => {
    return [
        { caption: "#", dataField: "numero_item", dataType: "number", alignment: "center", width: 40, allowEditing: false, fixed: true, fixedPosition: "left", sortOrder: 'asc' },
        { caption: "Classificação", dataField: "cad_produto_servico_classificacao_id", width: "280", lookup: true, dataSource: dataCadastro.produto_servico_classificacao ?? [], valueExpr: "id", displayExpr: "nome" },

        {
            caption: "Produto/Serviço",
            dataField: "cad_produto_servico_id",
            width: "280",
            lookup: true,
            dataSource: dataCadastro.produto_servico ?? [],
            valueExpr: "id",
            displayExpr: "nome_full"
        },

        { caption: "Centro de Custo", dataField: "cad_centro_custo_id", width: "180", lookup: true, dataSource: dataCadastro.centro_de_custo ?? [], valueExpr: "id", displayExpr: "nome" },
        { caption: "Un.", dataField: "cad_unidade_medida_id", width: "60", lookup: true, dataSource: dataCadastro.unidade_de_medida ?? [], valueExpr: "id", displayExpr: "codigo", alignment: "center" },
        { caption: "Qtd", dataField: "quantidade", dataType: "number", width: "80", alignment: "center", formatType: "fixedPoint", formatPrecision: 3 },

        {
            caption: "Valor",
            dataField: "valor_unitario",
            dataType: "number",
            width: "80",
            alignment: "center",
            formatType: "fixedPoint",
            formatPrecision: 3,
            calculateCellValue: CalculateCellValueValorUnitario,
            allowClearing: true

        },

        { caption: "Desc", dataField: "valor_desconto", dataType: "number", width: "65", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },



        { caption: "Acres", dataField: "valor_acrescimo", dataType: "number", width: "65", alignment: "center", formatType: "fixedPoint", formatPrecision: 2 },


        {
            name: "valor_total_virtual",

            caption: "Total",            
            dataField: "valor_total_virtual",
            dataType: "number",
            width: "80",
            // alignment: "center",
            formatType: "fixedPoint",
            formatPrecision: 3,
            calculateCellValue: CalculateCellValueValorTotal,
            allowClearing: true

            // dataField: "valor_total_virtual",
            // name: "valor_total",
            // caption: "Total",
            // calculateCellValue: CalculateCellValueValorTotal,
            // formatType: "fixedPoint",
            // formatPrecision: 2
        },


        { caption: "Observação", dataField: "observacao", dataType: "string", minWidth: "100" },
    ]
}