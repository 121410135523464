import React from 'react'
import SibeDatagrid from '../../components/DataGrid/DataGrid'

export default props => {
    const title = props.title || "Cadastro de Entidade"
    const apiURL = "/cadastro/entidade"
    const columnItems = [
        { caption: "CNPJ/CPF", dataField: "cnpj_cpf", alignment: "center", minWidth: "100" },
        { caption: "Razão Social / Nome completo", dataField: "razao_social_nome", dataType: "string", minWidth: "150", sortOrder: "asc" },
        { caption: "Nome Fantasia / Apelido", dataField: "nome_fantasia", dataType: "string", minWidth: "150" },
        { caption: "Município", dataField: "municipio_nome", dataType: "string", minWidth: "120" },
        { caption: "UF", dataField: "uf", dataType: "string", minWidth: "80", alignment: "center" },
        { caption: "Observação", dataField: "observacao", dataType: "string", minWidth: "120" },
    ]

    return <div>
        <SibeDatagrid
            forwardRef={props.forwardRef}
            title={title}
            apiURL={apiURL}
            columnItems={columnItems}
            hidePageContainer={props.hidePageContainer ?? false}
            height={props.height}
            onRowDblClick={props.onRowDblClick}
        />
    </div>
}